/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: available_events.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as available_events_pb from './available_events_pb'; // proto import: "available_events.proto"


export class AvailableEventServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListAvailableEvent = new grpcWeb.MethodDescriptor(
    '/proto.AvailableEventService/ListAvailableEvent',
    grpcWeb.MethodType.UNARY,
    available_events_pb.ListAvailableEventRequest,
    available_events_pb.ListAvailableEventResponse,
    (request: available_events_pb.ListAvailableEventRequest) => {
      return request.serializeBinary();
    },
    available_events_pb.ListAvailableEventResponse.deserializeBinary
  );

  listAvailableEvent(
    request: available_events_pb.ListAvailableEventRequest,
    metadata?: grpcWeb.Metadata | null): Promise<available_events_pb.ListAvailableEventResponse>;

  listAvailableEvent(
    request: available_events_pb.ListAvailableEventRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: available_events_pb.ListAvailableEventResponse) => void): grpcWeb.ClientReadableStream<available_events_pb.ListAvailableEventResponse>;

  listAvailableEvent(
    request: available_events_pb.ListAvailableEventRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: available_events_pb.ListAvailableEventResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AvailableEventService/ListAvailableEvent',
        request,
        metadata || {},
        this.methodDescriptorListAvailableEvent,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AvailableEventService/ListAvailableEvent',
    request,
    metadata || {},
    this.methodDescriptorListAvailableEvent);
  }

  methodDescriptorCountAvailableEvent = new grpcWeb.MethodDescriptor(
    '/proto.AvailableEventService/CountAvailableEvent',
    grpcWeb.MethodType.UNARY,
    available_events_pb.ListAvailableEventRequest,
    available_events_pb.CountAvailableEventResponse,
    (request: available_events_pb.ListAvailableEventRequest) => {
      return request.serializeBinary();
    },
    available_events_pb.CountAvailableEventResponse.deserializeBinary
  );

  countAvailableEvent(
    request: available_events_pb.ListAvailableEventRequest,
    metadata?: grpcWeb.Metadata | null): Promise<available_events_pb.CountAvailableEventResponse>;

  countAvailableEvent(
    request: available_events_pb.ListAvailableEventRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: available_events_pb.CountAvailableEventResponse) => void): grpcWeb.ClientReadableStream<available_events_pb.CountAvailableEventResponse>;

  countAvailableEvent(
    request: available_events_pb.ListAvailableEventRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: available_events_pb.CountAvailableEventResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AvailableEventService/CountAvailableEvent',
        request,
        metadata || {},
        this.methodDescriptorCountAvailableEvent,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AvailableEventService/CountAvailableEvent',
    request,
    metadata || {},
    this.methodDescriptorCountAvailableEvent);
  }

  methodDescriptorListAvailableEventProject = new grpcWeb.MethodDescriptor(
    '/proto.AvailableEventService/ListAvailableEventProject',
    grpcWeb.MethodType.UNARY,
    available_events_pb.ListAvailableEventProjectRequest,
    available_events_pb.ListAvailableEventProjectResponse,
    (request: available_events_pb.ListAvailableEventProjectRequest) => {
      return request.serializeBinary();
    },
    available_events_pb.ListAvailableEventProjectResponse.deserializeBinary
  );

  listAvailableEventProject(
    request: available_events_pb.ListAvailableEventProjectRequest,
    metadata?: grpcWeb.Metadata | null): Promise<available_events_pb.ListAvailableEventProjectResponse>;

  listAvailableEventProject(
    request: available_events_pb.ListAvailableEventProjectRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: available_events_pb.ListAvailableEventProjectResponse) => void): grpcWeb.ClientReadableStream<available_events_pb.ListAvailableEventProjectResponse>;

  listAvailableEventProject(
    request: available_events_pb.ListAvailableEventProjectRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: available_events_pb.ListAvailableEventProjectResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AvailableEventService/ListAvailableEventProject',
        request,
        metadata || {},
        this.methodDescriptorListAvailableEventProject,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AvailableEventService/ListAvailableEventProject',
    request,
    metadata || {},
    this.methodDescriptorListAvailableEventProject);
  }

  methodDescriptorListAvailableAction = new grpcWeb.MethodDescriptor(
    '/proto.AvailableEventService/ListAvailableAction',
    grpcWeb.MethodType.UNARY,
    available_events_pb.ListAvailableActionRequest,
    available_events_pb.ListAvailableActionResponse,
    (request: available_events_pb.ListAvailableActionRequest) => {
      return request.serializeBinary();
    },
    available_events_pb.ListAvailableActionResponse.deserializeBinary
  );

  listAvailableAction(
    request: available_events_pb.ListAvailableActionRequest,
    metadata?: grpcWeb.Metadata | null): Promise<available_events_pb.ListAvailableActionResponse>;

  listAvailableAction(
    request: available_events_pb.ListAvailableActionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: available_events_pb.ListAvailableActionResponse) => void): grpcWeb.ClientReadableStream<available_events_pb.ListAvailableActionResponse>;

  listAvailableAction(
    request: available_events_pb.ListAvailableActionRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: available_events_pb.ListAvailableActionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AvailableEventService/ListAvailableAction',
        request,
        metadata || {},
        this.methodDescriptorListAvailableAction,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AvailableEventService/ListAvailableAction',
    request,
    metadata || {},
    this.methodDescriptorListAvailableAction);
  }

}

