/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: loyalty.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as loyalty_pb from './loyalty_pb'; // proto import: "loyalty.proto"


export class ActivityLoyaltyServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListUserLoyalty = new grpcWeb.MethodDescriptor(
    '/proto.ActivityLoyaltyService/ListUserLoyalty',
    grpcWeb.MethodType.UNARY,
    loyalty_pb.ListUserLoyaltyRequest,
    loyalty_pb.ListUserLoyaltyResponse,
    (request: loyalty_pb.ListUserLoyaltyRequest) => {
      return request.serializeBinary();
    },
    loyalty_pb.ListUserLoyaltyResponse.deserializeBinary
  );

  listUserLoyalty(
    request: loyalty_pb.ListUserLoyaltyRequest,
    metadata?: grpcWeb.Metadata | null): Promise<loyalty_pb.ListUserLoyaltyResponse>;

  listUserLoyalty(
    request: loyalty_pb.ListUserLoyaltyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: loyalty_pb.ListUserLoyaltyResponse) => void): grpcWeb.ClientReadableStream<loyalty_pb.ListUserLoyaltyResponse>;

  listUserLoyalty(
    request: loyalty_pb.ListUserLoyaltyRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: loyalty_pb.ListUserLoyaltyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ActivityLoyaltyService/ListUserLoyalty',
        request,
        metadata || {},
        this.methodDescriptorListUserLoyalty,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ActivityLoyaltyService/ListUserLoyalty',
    request,
    metadata || {},
    this.methodDescriptorListUserLoyalty);
  }

  methodDescriptorUserInfoLoyalty = new grpcWeb.MethodDescriptor(
    '/proto.ActivityLoyaltyService/UserInfoLoyalty',
    grpcWeb.MethodType.UNARY,
    loyalty_pb.UserInfoLoyaltyRequest,
    loyalty_pb.UserInfoLoyaltyResponse,
    (request: loyalty_pb.UserInfoLoyaltyRequest) => {
      return request.serializeBinary();
    },
    loyalty_pb.UserInfoLoyaltyResponse.deserializeBinary
  );

  userInfoLoyalty(
    request: loyalty_pb.UserInfoLoyaltyRequest,
    metadata?: grpcWeb.Metadata | null): Promise<loyalty_pb.UserInfoLoyaltyResponse>;

  userInfoLoyalty(
    request: loyalty_pb.UserInfoLoyaltyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: loyalty_pb.UserInfoLoyaltyResponse) => void): grpcWeb.ClientReadableStream<loyalty_pb.UserInfoLoyaltyResponse>;

  userInfoLoyalty(
    request: loyalty_pb.UserInfoLoyaltyRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: loyalty_pb.UserInfoLoyaltyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ActivityLoyaltyService/UserInfoLoyalty',
        request,
        metadata || {},
        this.methodDescriptorUserInfoLoyalty,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ActivityLoyaltyService/UserInfoLoyalty',
    request,
    metadata || {},
    this.methodDescriptorUserInfoLoyalty);
  }

  methodDescriptorLastAchievementsLoyalty = new grpcWeb.MethodDescriptor(
    '/proto.ActivityLoyaltyService/LastAchievementsLoyalty',
    grpcWeb.MethodType.UNARY,
    loyalty_pb.LastAchievementsLoyaltyRequest,
    loyalty_pb.LastAchievementsLoyaltyResponse,
    (request: loyalty_pb.LastAchievementsLoyaltyRequest) => {
      return request.serializeBinary();
    },
    loyalty_pb.LastAchievementsLoyaltyResponse.deserializeBinary
  );

  lastAchievementsLoyalty(
    request: loyalty_pb.LastAchievementsLoyaltyRequest,
    metadata?: grpcWeb.Metadata | null): Promise<loyalty_pb.LastAchievementsLoyaltyResponse>;

  lastAchievementsLoyalty(
    request: loyalty_pb.LastAchievementsLoyaltyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: loyalty_pb.LastAchievementsLoyaltyResponse) => void): grpcWeb.ClientReadableStream<loyalty_pb.LastAchievementsLoyaltyResponse>;

  lastAchievementsLoyalty(
    request: loyalty_pb.LastAchievementsLoyaltyRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: loyalty_pb.LastAchievementsLoyaltyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ActivityLoyaltyService/LastAchievementsLoyalty',
        request,
        metadata || {},
        this.methodDescriptorLastAchievementsLoyalty,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ActivityLoyaltyService/LastAchievementsLoyalty',
    request,
    metadata || {},
    this.methodDescriptorLastAchievementsLoyalty);
  }

  methodDescriptorAllAchievementsLoyalty = new grpcWeb.MethodDescriptor(
    '/proto.ActivityLoyaltyService/AllAchievementsLoyalty',
    grpcWeb.MethodType.UNARY,
    loyalty_pb.AllAchievementsLoyaltyRequest,
    loyalty_pb.AllAchievementsLoyaltyResponse,
    (request: loyalty_pb.AllAchievementsLoyaltyRequest) => {
      return request.serializeBinary();
    },
    loyalty_pb.AllAchievementsLoyaltyResponse.deserializeBinary
  );

  allAchievementsLoyalty(
    request: loyalty_pb.AllAchievementsLoyaltyRequest,
    metadata?: grpcWeb.Metadata | null): Promise<loyalty_pb.AllAchievementsLoyaltyResponse>;

  allAchievementsLoyalty(
    request: loyalty_pb.AllAchievementsLoyaltyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: loyalty_pb.AllAchievementsLoyaltyResponse) => void): grpcWeb.ClientReadableStream<loyalty_pb.AllAchievementsLoyaltyResponse>;

  allAchievementsLoyalty(
    request: loyalty_pb.AllAchievementsLoyaltyRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: loyalty_pb.AllAchievementsLoyaltyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ActivityLoyaltyService/AllAchievementsLoyalty',
        request,
        metadata || {},
        this.methodDescriptorAllAchievementsLoyalty,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ActivityLoyaltyService/AllAchievementsLoyalty',
    request,
    metadata || {},
    this.methodDescriptorAllAchievementsLoyalty);
  }

  methodDescriptorListFilterProjectLoyalty = new grpcWeb.MethodDescriptor(
    '/proto.ActivityLoyaltyService/ListFilterProjectLoyalty',
    grpcWeb.MethodType.UNARY,
    loyalty_pb.ListFilterProjectLoyaltyRequest,
    loyalty_pb.ListFilterProjectLoyaltyResponse,
    (request: loyalty_pb.ListFilterProjectLoyaltyRequest) => {
      return request.serializeBinary();
    },
    loyalty_pb.ListFilterProjectLoyaltyResponse.deserializeBinary
  );

  listFilterProjectLoyalty(
    request: loyalty_pb.ListFilterProjectLoyaltyRequest,
    metadata?: grpcWeb.Metadata | null): Promise<loyalty_pb.ListFilterProjectLoyaltyResponse>;

  listFilterProjectLoyalty(
    request: loyalty_pb.ListFilterProjectLoyaltyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: loyalty_pb.ListFilterProjectLoyaltyResponse) => void): grpcWeb.ClientReadableStream<loyalty_pb.ListFilterProjectLoyaltyResponse>;

  listFilterProjectLoyalty(
    request: loyalty_pb.ListFilterProjectLoyaltyRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: loyalty_pb.ListFilterProjectLoyaltyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ActivityLoyaltyService/ListFilterProjectLoyalty',
        request,
        metadata || {},
        this.methodDescriptorListFilterProjectLoyalty,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ActivityLoyaltyService/ListFilterProjectLoyalty',
    request,
    metadata || {},
    this.methodDescriptorListFilterProjectLoyalty);
  }

  methodDescriptorListFilterTransactionLoyalty = new grpcWeb.MethodDescriptor(
    '/proto.ActivityLoyaltyService/ListFilterTransactionLoyalty',
    grpcWeb.MethodType.UNARY,
    loyalty_pb.ListFilterTransactionLoyaltyRequest,
    loyalty_pb.ListFilterTransactionLoyaltyResponse,
    (request: loyalty_pb.ListFilterTransactionLoyaltyRequest) => {
      return request.serializeBinary();
    },
    loyalty_pb.ListFilterTransactionLoyaltyResponse.deserializeBinary
  );

  listFilterTransactionLoyalty(
    request: loyalty_pb.ListFilterTransactionLoyaltyRequest,
    metadata?: grpcWeb.Metadata | null): Promise<loyalty_pb.ListFilterTransactionLoyaltyResponse>;

  listFilterTransactionLoyalty(
    request: loyalty_pb.ListFilterTransactionLoyaltyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: loyalty_pb.ListFilterTransactionLoyaltyResponse) => void): grpcWeb.ClientReadableStream<loyalty_pb.ListFilterTransactionLoyaltyResponse>;

  listFilterTransactionLoyalty(
    request: loyalty_pb.ListFilterTransactionLoyaltyRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: loyalty_pb.ListFilterTransactionLoyaltyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ActivityLoyaltyService/ListFilterTransactionLoyalty',
        request,
        metadata || {},
        this.methodDescriptorListFilterTransactionLoyalty,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ActivityLoyaltyService/ListFilterTransactionLoyalty',
    request,
    metadata || {},
    this.methodDescriptorListFilterTransactionLoyalty);
  }

  methodDescriptorGetAggTaskListStat = new grpcWeb.MethodDescriptor(
    '/proto.ActivityLoyaltyService/GetAggTaskListStat',
    grpcWeb.MethodType.UNARY,
    loyalty_pb.GetAggTaskListStatRequest,
    loyalty_pb.GetAggTaskListStatResponse,
    (request: loyalty_pb.GetAggTaskListStatRequest) => {
      return request.serializeBinary();
    },
    loyalty_pb.GetAggTaskListStatResponse.deserializeBinary
  );

  getAggTaskListStat(
    request: loyalty_pb.GetAggTaskListStatRequest,
    metadata?: grpcWeb.Metadata | null): Promise<loyalty_pb.GetAggTaskListStatResponse>;

  getAggTaskListStat(
    request: loyalty_pb.GetAggTaskListStatRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: loyalty_pb.GetAggTaskListStatResponse) => void): grpcWeb.ClientReadableStream<loyalty_pb.GetAggTaskListStatResponse>;

  getAggTaskListStat(
    request: loyalty_pb.GetAggTaskListStatRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: loyalty_pb.GetAggTaskListStatResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ActivityLoyaltyService/GetAggTaskListStat',
        request,
        metadata || {},
        this.methodDescriptorGetAggTaskListStat,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ActivityLoyaltyService/GetAggTaskListStat',
    request,
    metadata || {},
    this.methodDescriptorGetAggTaskListStat);
  }

  methodDescriptorExportListUserLoyalty = new grpcWeb.MethodDescriptor(
    '/proto.ActivityLoyaltyService/ExportListUserLoyalty',
    grpcWeb.MethodType.UNARY,
    loyalty_pb.ListUserLoyaltyRequest,
    loyalty_pb.ExportListUserLoyaltyResponse,
    (request: loyalty_pb.ListUserLoyaltyRequest) => {
      return request.serializeBinary();
    },
    loyalty_pb.ExportListUserLoyaltyResponse.deserializeBinary
  );

  exportListUserLoyalty(
    request: loyalty_pb.ListUserLoyaltyRequest,
    metadata?: grpcWeb.Metadata | null): Promise<loyalty_pb.ExportListUserLoyaltyResponse>;

  exportListUserLoyalty(
    request: loyalty_pb.ListUserLoyaltyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: loyalty_pb.ExportListUserLoyaltyResponse) => void): grpcWeb.ClientReadableStream<loyalty_pb.ExportListUserLoyaltyResponse>;

  exportListUserLoyalty(
    request: loyalty_pb.ListUserLoyaltyRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: loyalty_pb.ExportListUserLoyaltyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ActivityLoyaltyService/ExportListUserLoyalty',
        request,
        metadata || {},
        this.methodDescriptorExportListUserLoyalty,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ActivityLoyaltyService/ExportListUserLoyalty',
    request,
    metadata || {},
    this.methodDescriptorExportListUserLoyalty);
  }

}

