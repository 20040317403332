/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: scorecard_account_project.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as scorecard_account_project_pb from './scorecard_account_project_pb'; // proto import: "scorecard_account_project.proto"


export class ScorecardAccountProjectServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListScorecardAccountProject = new grpcWeb.MethodDescriptor(
    '/proto.ScorecardAccountProjectService/ListScorecardAccountProject',
    grpcWeb.MethodType.UNARY,
    scorecard_account_project_pb.ListScorecardAccountProjectRequest,
    scorecard_account_project_pb.ListScorecardAccountProjectResponse,
    (request: scorecard_account_project_pb.ListScorecardAccountProjectRequest) => {
      return request.serializeBinary();
    },
    scorecard_account_project_pb.ListScorecardAccountProjectResponse.deserializeBinary
  );

  listScorecardAccountProject(
    request: scorecard_account_project_pb.ListScorecardAccountProjectRequest,
    metadata?: grpcWeb.Metadata | null): Promise<scorecard_account_project_pb.ListScorecardAccountProjectResponse>;

  listScorecardAccountProject(
    request: scorecard_account_project_pb.ListScorecardAccountProjectRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: scorecard_account_project_pb.ListScorecardAccountProjectResponse) => void): grpcWeb.ClientReadableStream<scorecard_account_project_pb.ListScorecardAccountProjectResponse>;

  listScorecardAccountProject(
    request: scorecard_account_project_pb.ListScorecardAccountProjectRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: scorecard_account_project_pb.ListScorecardAccountProjectResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ScorecardAccountProjectService/ListScorecardAccountProject',
        request,
        metadata || {},
        this.methodDescriptorListScorecardAccountProject,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ScorecardAccountProjectService/ListScorecardAccountProject',
    request,
    metadata || {},
    this.methodDescriptorListScorecardAccountProject);
  }

  methodDescriptorActivityChart = new grpcWeb.MethodDescriptor(
    '/proto.ScorecardAccountProjectService/ActivityChart',
    grpcWeb.MethodType.UNARY,
    scorecard_account_project_pb.ActivityChartRequest,
    scorecard_account_project_pb.ActivityChartResponse,
    (request: scorecard_account_project_pb.ActivityChartRequest) => {
      return request.serializeBinary();
    },
    scorecard_account_project_pb.ActivityChartResponse.deserializeBinary
  );

  activityChart(
    request: scorecard_account_project_pb.ActivityChartRequest,
    metadata?: grpcWeb.Metadata | null): Promise<scorecard_account_project_pb.ActivityChartResponse>;

  activityChart(
    request: scorecard_account_project_pb.ActivityChartRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: scorecard_account_project_pb.ActivityChartResponse) => void): grpcWeb.ClientReadableStream<scorecard_account_project_pb.ActivityChartResponse>;

  activityChart(
    request: scorecard_account_project_pb.ActivityChartRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: scorecard_account_project_pb.ActivityChartResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ScorecardAccountProjectService/ActivityChart',
        request,
        metadata || {},
        this.methodDescriptorActivityChart,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ScorecardAccountProjectService/ActivityChart',
    request,
    metadata || {},
    this.methodDescriptorActivityChart);
  }

}

