import script from "./RecList.vue?vue&type=script&setup=true&lang=ts"
export * from "./RecList.vue?vue&type=script&setup=true&lang=ts"

import "./RecList.vue?vue&type=style&index=0&id=3f727fb2&lang=scss"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard});
