import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, Transition as _Transition, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SidebarComponent = _resolveComponent("SidebarComponent")!
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_q_page_container = _resolveComponent("q-page-container")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createBlock(_component_q_layout, { view: "lHh Lpr lFf" }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "app-main",
        style: _normalizeStyle((_ctx.$route.meta.style as StyleValue))
      }, [
        _createVNode(_component_SidebarComponent, {
          "user-data": _ctx.userData,
          "date-update": _ctx.dateUpdate,
          "update-status": _ctx.updateStatus,
          "product-info-items": _ctx.productInfoItems
        }, null, 8, ["user-data", "date-update", "update-status", "product-info-items"]),
        _createVNode(_component_q_page_container, {
          style: _normalizeStyle(_ctx.$route.meta.style)
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Breadcrumbs),
            _createVNode(_component_router_view, null, {
              default: _withCtx(({ Component, route }) => [
                _createVNode(_Transition, {
                  name: _ctx.transitionName,
                  mode: "out-in"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(), _createBlock(_KeepAlive, {
                      max: 1,
                      exclude: ['UserProjects', 'UserProfile','MainPageScenario','MainPageScenarioAction','SegmentationPage','SegmentationActionPage']
                    }, [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                        key: route.path
                      }))
                    ], 1024))
                  ]),
                  _: 2
                }, 1032, ["name"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["style"])
      ], 4)
    ]),
    _: 1
  }))
}