/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: point.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as point_pb from './point_pb';


export class PointServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListPointEarned = new grpcWeb.MethodDescriptor(
    '/proto.PointService/ListPointEarned',
    grpcWeb.MethodType.UNARY,
    point_pb.ListPointEarnedRequest,
    point_pb.ListPointEarnedResponse,
    (request: point_pb.ListPointEarnedRequest) => {
      return request.serializeBinary();
    },
    point_pb.ListPointEarnedResponse.deserializeBinary
  );

  listPointEarned(
    request: point_pb.ListPointEarnedRequest,
    metadata: grpcWeb.Metadata | null): Promise<point_pb.ListPointEarnedResponse>;

  listPointEarned(
    request: point_pb.ListPointEarnedRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: point_pb.ListPointEarnedResponse) => void): grpcWeb.ClientReadableStream<point_pb.ListPointEarnedResponse>;

  listPointEarned(
    request: point_pb.ListPointEarnedRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: point_pb.ListPointEarnedResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.PointService/ListPointEarned',
        request,
        metadata || {},
        this.methodDescriptorListPointEarned,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.PointService/ListPointEarned',
    request,
    metadata || {},
    this.methodDescriptorListPointEarned);
  }

  methodDescriptorListPointSpent = new grpcWeb.MethodDescriptor(
    '/proto.PointService/ListPointSpent',
    grpcWeb.MethodType.UNARY,
    point_pb.ListPointSpentRequest,
    point_pb.ListPointSpentResponse,
    (request: point_pb.ListPointSpentRequest) => {
      return request.serializeBinary();
    },
    point_pb.ListPointSpentResponse.deserializeBinary
  );

  listPointSpent(
    request: point_pb.ListPointSpentRequest,
    metadata: grpcWeb.Metadata | null): Promise<point_pb.ListPointSpentResponse>;

  listPointSpent(
    request: point_pb.ListPointSpentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: point_pb.ListPointSpentResponse) => void): grpcWeb.ClientReadableStream<point_pb.ListPointSpentResponse>;

  listPointSpent(
    request: point_pb.ListPointSpentRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: point_pb.ListPointSpentResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.PointService/ListPointSpent',
        request,
        metadata || {},
        this.methodDescriptorListPointSpent,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.PointService/ListPointSpent',
    request,
    metadata || {},
    this.methodDescriptorListPointSpent);
  }

  methodDescriptorListPointEarnedShedule = new grpcWeb.MethodDescriptor(
    '/proto.PointService/ListPointEarnedShedule',
    grpcWeb.MethodType.UNARY,
    point_pb.ListPointEarnedSheduleRequest,
    point_pb.ListPointEarnedSheduleResponse,
    (request: point_pb.ListPointEarnedSheduleRequest) => {
      return request.serializeBinary();
    },
    point_pb.ListPointEarnedSheduleResponse.deserializeBinary
  );

  listPointEarnedShedule(
    request: point_pb.ListPointEarnedSheduleRequest,
    metadata: grpcWeb.Metadata | null): Promise<point_pb.ListPointEarnedSheduleResponse>;

  listPointEarnedShedule(
    request: point_pb.ListPointEarnedSheduleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: point_pb.ListPointEarnedSheduleResponse) => void): grpcWeb.ClientReadableStream<point_pb.ListPointEarnedSheduleResponse>;

  listPointEarnedShedule(
    request: point_pb.ListPointEarnedSheduleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: point_pb.ListPointEarnedSheduleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.PointService/ListPointEarnedShedule',
        request,
        metadata || {},
        this.methodDescriptorListPointEarnedShedule,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.PointService/ListPointEarnedShedule',
    request,
    metadata || {},
    this.methodDescriptorListPointEarnedShedule);
  }

  methodDescriptorListPointSpentShedule = new grpcWeb.MethodDescriptor(
    '/proto.PointService/ListPointSpentShedule',
    grpcWeb.MethodType.UNARY,
    point_pb.ListPointSpentSheduleRequest,
    point_pb.ListPointSpentSheduleResponse,
    (request: point_pb.ListPointSpentSheduleRequest) => {
      return request.serializeBinary();
    },
    point_pb.ListPointSpentSheduleResponse.deserializeBinary
  );

  listPointSpentShedule(
    request: point_pb.ListPointSpentSheduleRequest,
    metadata: grpcWeb.Metadata | null): Promise<point_pb.ListPointSpentSheduleResponse>;

  listPointSpentShedule(
    request: point_pb.ListPointSpentSheduleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: point_pb.ListPointSpentSheduleResponse) => void): grpcWeb.ClientReadableStream<point_pb.ListPointSpentSheduleResponse>;

  listPointSpentShedule(
    request: point_pb.ListPointSpentSheduleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: point_pb.ListPointSpentSheduleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.PointService/ListPointSpentShedule',
        request,
        metadata || {},
        this.methodDescriptorListPointSpentShedule,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.PointService/ListPointSpentShedule',
    request,
    metadata || {},
    this.methodDescriptorListPointSpentShedule);
  }

  methodDescriptorListPointBalanceShedule = new grpcWeb.MethodDescriptor(
    '/proto.PointService/ListPointBalanceShedule',
    grpcWeb.MethodType.UNARY,
    point_pb.ListPointBalanceSheduleRequest,
    point_pb.ListPointBalanceSheduleResponse,
    (request: point_pb.ListPointBalanceSheduleRequest) => {
      return request.serializeBinary();
    },
    point_pb.ListPointBalanceSheduleResponse.deserializeBinary
  );

  listPointBalanceShedule(
    request: point_pb.ListPointBalanceSheduleRequest,
    metadata: grpcWeb.Metadata | null): Promise<point_pb.ListPointBalanceSheduleResponse>;

  listPointBalanceShedule(
    request: point_pb.ListPointBalanceSheduleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: point_pb.ListPointBalanceSheduleResponse) => void): grpcWeb.ClientReadableStream<point_pb.ListPointBalanceSheduleResponse>;

  listPointBalanceShedule(
    request: point_pb.ListPointBalanceSheduleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: point_pb.ListPointBalanceSheduleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.PointService/ListPointBalanceShedule',
        request,
        metadata || {},
        this.methodDescriptorListPointBalanceShedule,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.PointService/ListPointBalanceShedule',
    request,
    metadata || {},
    this.methodDescriptorListPointBalanceShedule);
  }

  methodDescriptorCountPoint = new grpcWeb.MethodDescriptor(
    '/proto.PointService/CountPoint',
    grpcWeb.MethodType.UNARY,
    point_pb.CountPointRequest,
    point_pb.CountPointResponse,
    (request: point_pb.CountPointRequest) => {
      return request.serializeBinary();
    },
    point_pb.CountPointResponse.deserializeBinary
  );

  countPoint(
    request: point_pb.CountPointRequest,
    metadata: grpcWeb.Metadata | null): Promise<point_pb.CountPointResponse>;

  countPoint(
    request: point_pb.CountPointRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: point_pb.CountPointResponse) => void): grpcWeb.ClientReadableStream<point_pb.CountPointResponse>;

  countPoint(
    request: point_pb.CountPointRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: point_pb.CountPointResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.PointService/CountPoint',
        request,
        metadata || {},
        this.methodDescriptorCountPoint,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.PointService/CountPoint',
    request,
    metadata || {},
    this.methodDescriptorCountPoint);
  }

  methodDescriptorCountPointSegment = new grpcWeb.MethodDescriptor(
    '/proto.PointService/CountPointSegment',
    grpcWeb.MethodType.UNARY,
    point_pb.CountPointSegmentRequest,
    point_pb.CountPointSegmentResponse,
    (request: point_pb.CountPointSegmentRequest) => {
      return request.serializeBinary();
    },
    point_pb.CountPointSegmentResponse.deserializeBinary
  );

  countPointSegment(
    request: point_pb.CountPointSegmentRequest,
    metadata: grpcWeb.Metadata | null): Promise<point_pb.CountPointSegmentResponse>;

  countPointSegment(
    request: point_pb.CountPointSegmentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: point_pb.CountPointSegmentResponse) => void): grpcWeb.ClientReadableStream<point_pb.CountPointSegmentResponse>;

  countPointSegment(
    request: point_pb.CountPointSegmentRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: point_pb.CountPointSegmentResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.PointService/CountPointSegment',
        request,
        metadata || {},
        this.methodDescriptorCountPointSegment,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.PointService/CountPointSegment',
    request,
    metadata || {},
    this.methodDescriptorCountPointSegment);
  }

  methodDescriptorCountPointEarnedByProject = new grpcWeb.MethodDescriptor(
    '/proto.PointService/CountPointEarnedByProject',
    grpcWeb.MethodType.UNARY,
    point_pb.CountPointEarnedByProjectRequest,
    point_pb.CountPointEarnedByProjectResponse,
    (request: point_pb.CountPointEarnedByProjectRequest) => {
      return request.serializeBinary();
    },
    point_pb.CountPointEarnedByProjectResponse.deserializeBinary
  );

  countPointEarnedByProject(
    request: point_pb.CountPointEarnedByProjectRequest,
    metadata: grpcWeb.Metadata | null): Promise<point_pb.CountPointEarnedByProjectResponse>;

  countPointEarnedByProject(
    request: point_pb.CountPointEarnedByProjectRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: point_pb.CountPointEarnedByProjectResponse) => void): grpcWeb.ClientReadableStream<point_pb.CountPointEarnedByProjectResponse>;

  countPointEarnedByProject(
    request: point_pb.CountPointEarnedByProjectRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: point_pb.CountPointEarnedByProjectResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.PointService/CountPointEarnedByProject',
        request,
        metadata || {},
        this.methodDescriptorCountPointEarnedByProject,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.PointService/CountPointEarnedByProject',
    request,
    metadata || {},
    this.methodDescriptorCountPointEarnedByProject);
  }

  methodDescriptorExportListPointEarned = new grpcWeb.MethodDescriptor(
    '/proto.PointService/ExportListPointEarned',
    grpcWeb.MethodType.UNARY,
    point_pb.ListPointEarnedRequest,
    point_pb.ExportListPointResponse,
    (request: point_pb.ListPointEarnedRequest) => {
      return request.serializeBinary();
    },
    point_pb.ExportListPointResponse.deserializeBinary
  );

  exportListPointEarned(
    request: point_pb.ListPointEarnedRequest,
    metadata: grpcWeb.Metadata | null): Promise<point_pb.ExportListPointResponse>;

  exportListPointEarned(
    request: point_pb.ListPointEarnedRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: point_pb.ExportListPointResponse) => void): grpcWeb.ClientReadableStream<point_pb.ExportListPointResponse>;

  exportListPointEarned(
    request: point_pb.ListPointEarnedRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: point_pb.ExportListPointResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.PointService/ExportListPointEarned',
        request,
        metadata || {},
        this.methodDescriptorExportListPointEarned,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.PointService/ExportListPointEarned',
    request,
    metadata || {},
    this.methodDescriptorExportListPointEarned);
  }

  methodDescriptorExportListPointSpent = new grpcWeb.MethodDescriptor(
    '/proto.PointService/ExportListPointSpent',
    grpcWeb.MethodType.UNARY,
    point_pb.ListPointSpentRequest,
    point_pb.ExportListPointResponse,
    (request: point_pb.ListPointSpentRequest) => {
      return request.serializeBinary();
    },
    point_pb.ExportListPointResponse.deserializeBinary
  );

  exportListPointSpent(
    request: point_pb.ListPointSpentRequest,
    metadata: grpcWeb.Metadata | null): Promise<point_pb.ExportListPointResponse>;

  exportListPointSpent(
    request: point_pb.ListPointSpentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: point_pb.ExportListPointResponse) => void): grpcWeb.ClientReadableStream<point_pb.ExportListPointResponse>;

  exportListPointSpent(
    request: point_pb.ListPointSpentRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: point_pb.ExportListPointResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.PointService/ExportListPointSpent',
        request,
        metadata || {},
        this.methodDescriptorExportListPointSpent,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.PointService/ExportListPointSpent',
    request,
    metadata || {},
    this.methodDescriptorExportListPointSpent);
  }

  methodDescriptorListPointTypes = new grpcWeb.MethodDescriptor(
    '/proto.PointService/ListPointTypes',
    grpcWeb.MethodType.UNARY,
    point_pb.ListPointTypesRequest,
    point_pb.ListPointTypesResponse,
    (request: point_pb.ListPointTypesRequest) => {
      return request.serializeBinary();
    },
    point_pb.ListPointTypesResponse.deserializeBinary
  );

  listPointTypes(
    request: point_pb.ListPointTypesRequest,
    metadata: grpcWeb.Metadata | null): Promise<point_pb.ListPointTypesResponse>;

  listPointTypes(
    request: point_pb.ListPointTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: point_pb.ListPointTypesResponse) => void): grpcWeb.ClientReadableStream<point_pb.ListPointTypesResponse>;

  listPointTypes(
    request: point_pb.ListPointTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: point_pb.ListPointTypesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.PointService/ListPointTypes',
        request,
        metadata || {},
        this.methodDescriptorListPointTypes,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.PointService/ListPointTypes',
    request,
    metadata || {},
    this.methodDescriptorListPointTypes);
  }

}

