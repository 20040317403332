import { StyleValue } from "vue";
import { RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [
  {
    path: '/recommend-system/main-page-scenarios',
    name: 'MainPageScenariosView',
    component: () => import('../pages/MainPageScenarios/MainPageScenario.vue'),
    meta: {
      breadCrumb: [
        { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
        { text: 'Рекомендательная система', to: { name: 'RecSystem' } },
        { text: 'Сценарии главной страницы' }
      ],
    },
  },
  {
    path: '/recommend-system/main-page-scenarios/scenario-action',
    name: 'MainPageScenarioEdit',
    component: () => import('../pages/MainPageScenarios/MainPageScenarioAction.vue'),
    meta: {
      breadCrumb: [
        { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
        { text: 'Рекомендательная система', to: { name: 'RecSystem' } },
        { text: 'Сценарии главной страницы', to: { name: 'MainPageScenariosView' } },
        { text: 'Сценарий' }
      ],
      style: {
        backgroundColor: 'white',
        transition: 'all .3s ease'
      } satisfies StyleValue
    },
  },
]

export default routes
