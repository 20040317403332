import { DashboardProjectInfoServiceClient } from '../../proto/pb/Project_infoServiceClientPb'
import {
  GetDashboardAllCountRequest,
  ListDashboardProjectInfoRequest,
  DashboardProjectInfoPagination,
  ListDashboardProjectInfoAccountRequest,
  PersonalListDashboardProjectInfoAccountRequest,
} from '../../proto/pb/project_info_pb'
import { RefreshTokenInterceptor } from "@/components/modules/users/services/api/RefreshTokenInterceptor";

export class IntersectionUsersApi {
  client: DashboardProjectInfoServiceClient
  metadata: any

  constructor() {
    const host: string = (window as any).VUE_APP_API_URL ?? ''
    this.client = new DashboardProjectInfoServiceClient(host, null, { unaryInterceptors: [new RefreshTokenInterceptor()] })
    this.metadata = {
      Authorization: localStorage.getItem('access-key')
    }
  }

  createPagination(data: any) {
    const pagination = new DashboardProjectInfoPagination()
    const page: number = data.page !== undefined ? Number(data.page) : 1
    const limit: number =
      data.limit !== undefined ? Number(data.limit) : 1000
    const pages: number = data.pages !== undefined ? Number(data.pages) : 0
    const cnt: number = data.cnt !== undefined ? Number(data.cnt) : 0

    // Устанавливаем пагинацию
    pagination.setPage(page)
    pagination.setLimit(limit)
    pagination.setPages(pages).setCnt(cnt)

    return pagination
  }

  getDashboardAllCountRequest(data: any) {
    const request = new GetDashboardAllCountRequest()

    request
      .setActivityIdsList(data.activity)
      .setLoyaltyIdsList(data.loyalty)
      .setUsefulnessIdsList(data.useful)
      .setInterestIdsList(data.interest)
      .setDateBegin(data.date_begin)
      .setDateEnd(data.date_end)

    return this.client.getDashboardAllCount(request, this.metadata)
  }

  getListDashboardProjectInfoRequest(date: any) {
    const request = new ListDashboardProjectInfoRequest()

    request
      .setActivityIdsList(date.activity)
      .setLoyaltyIdsList(date.loyalty)
      .setUsefulnessIdsList(date.useful)
      .setInterestIdsList(date.interest)
      .setDateBegin(date.date_begin)
      .setDateEnd(date.date_end)

    console.log('REQ', request)

    return this.client.listDashboardProjectInfo(request, this.metadata)
  }

  getAccountFlagRequest(pagination: any, data: any) {
    const request = new ListDashboardProjectInfoAccountRequest()

    request
      .setPagination(this.createPagination(pagination))
      .setAllProject(data.all_project)
      .setProjectIdsList(data.project_ids)
      .setActivityIdsList(data.activity)
      .setLoyaltyIdsList(data.loyalty)
      .setUsefulnessIdsList(data.useful)
      .setInterestIdsList(data.interest)
      .setDateBegin(data.date_begin)
      .setDateEnd(data.date_end)

    console.log('account flag req', request.toObject())

    return this.client.listDashboardProjectInfoAccount(request, this.metadata)
  }

  getExportTable(pagination: any, data: any) {
    const request = new ListDashboardProjectInfoAccountRequest()

    request
      .setPagination(this.createPagination(pagination))
      .setAllProject(data.all_project)
      .setProjectIdsList(data.project_ids)
      .setActivityIdsList(data.activity)
      .setLoyaltyIdsList(data.loyalty)
      .setUsefulnessIdsList(data.useful)
      .setInterestIdsList(data.interest)
      .setProjectIdsList(data.project_ids)
      .setDateBegin(data.date_begin)
      .setDateEnd(data.date_end)

    console.log('EXPORT INT TABLE REQ', request.toObject())

    return this.client.listDashboardProjectInfoAccount(request, this.metadata)
  }

  getPersonalProjectInfo(data: any) {
    const request = new PersonalListDashboardProjectInfoAccountRequest()

    request
      .setActivityIdsList(data.activity)
      .setLoyaltyIdsList(data.loyalty)
      .setUsefulnessIdsList(data.useful)
      .setInterestIdsList(data.interest)
      .setDateBegin(data.date_begin)
      .setDateEnd(data.date_end)
      .setAllProject(data.all_project)
      .setProjectIdsList(data.project_ids)

    console.log('REQ', request)

    return this.client.personalListDashboardProjectInfoAccount(request, this.metadata)
  }
}
