/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: metric.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as metric_pb from './metric_pb'; // proto import: "metric.proto"


export class ActivityMetricServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateActivityMetric = new grpcWeb.MethodDescriptor(
    '/proto.ActivityMetricService/CreateActivityMetric',
    grpcWeb.MethodType.UNARY,
    metric_pb.CreateActivityMetricRequest,
    metric_pb.CreateActivityMetricResponse,
    (request: metric_pb.CreateActivityMetricRequest) => {
      return request.serializeBinary();
    },
    metric_pb.CreateActivityMetricResponse.deserializeBinary
  );

  createActivityMetric(
    request: metric_pb.CreateActivityMetricRequest,
    metadata?: grpcWeb.Metadata | null): Promise<metric_pb.CreateActivityMetricResponse>;

  createActivityMetric(
    request: metric_pb.CreateActivityMetricRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: metric_pb.CreateActivityMetricResponse) => void): grpcWeb.ClientReadableStream<metric_pb.CreateActivityMetricResponse>;

  createActivityMetric(
    request: metric_pb.CreateActivityMetricRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: metric_pb.CreateActivityMetricResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ActivityMetricService/CreateActivityMetric',
        request,
        metadata || {},
        this.methodDescriptorCreateActivityMetric,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ActivityMetricService/CreateActivityMetric',
    request,
    metadata || {},
    this.methodDescriptorCreateActivityMetric);
  }

  methodDescriptorUpdateActivityMetric = new grpcWeb.MethodDescriptor(
    '/proto.ActivityMetricService/UpdateActivityMetric',
    grpcWeb.MethodType.UNARY,
    metric_pb.UpdateActivityMetricRequest,
    metric_pb.UpdateActivityMetricResponse,
    (request: metric_pb.UpdateActivityMetricRequest) => {
      return request.serializeBinary();
    },
    metric_pb.UpdateActivityMetricResponse.deserializeBinary
  );

  updateActivityMetric(
    request: metric_pb.UpdateActivityMetricRequest,
    metadata?: grpcWeb.Metadata | null): Promise<metric_pb.UpdateActivityMetricResponse>;

  updateActivityMetric(
    request: metric_pb.UpdateActivityMetricRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: metric_pb.UpdateActivityMetricResponse) => void): grpcWeb.ClientReadableStream<metric_pb.UpdateActivityMetricResponse>;

  updateActivityMetric(
    request: metric_pb.UpdateActivityMetricRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: metric_pb.UpdateActivityMetricResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ActivityMetricService/UpdateActivityMetric',
        request,
        metadata || {},
        this.methodDescriptorUpdateActivityMetric,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ActivityMetricService/UpdateActivityMetric',
    request,
    metadata || {},
    this.methodDescriptorUpdateActivityMetric);
  }

  methodDescriptorDeleteActivityMetric = new grpcWeb.MethodDescriptor(
    '/proto.ActivityMetricService/DeleteActivityMetric',
    grpcWeb.MethodType.UNARY,
    metric_pb.DeleteActivityMetricRequest,
    metric_pb.DeleteActivityMetricResponse,
    (request: metric_pb.DeleteActivityMetricRequest) => {
      return request.serializeBinary();
    },
    metric_pb.DeleteActivityMetricResponse.deserializeBinary
  );

  deleteActivityMetric(
    request: metric_pb.DeleteActivityMetricRequest,
    metadata?: grpcWeb.Metadata | null): Promise<metric_pb.DeleteActivityMetricResponse>;

  deleteActivityMetric(
    request: metric_pb.DeleteActivityMetricRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: metric_pb.DeleteActivityMetricResponse) => void): grpcWeb.ClientReadableStream<metric_pb.DeleteActivityMetricResponse>;

  deleteActivityMetric(
    request: metric_pb.DeleteActivityMetricRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: metric_pb.DeleteActivityMetricResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ActivityMetricService/DeleteActivityMetric',
        request,
        metadata || {},
        this.methodDescriptorDeleteActivityMetric,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ActivityMetricService/DeleteActivityMetric',
    request,
    metadata || {},
    this.methodDescriptorDeleteActivityMetric);
  }

  methodDescriptorListActivityMetric = new grpcWeb.MethodDescriptor(
    '/proto.ActivityMetricService/ListActivityMetric',
    grpcWeb.MethodType.UNARY,
    metric_pb.ListActivityMetricRequest,
    metric_pb.ListActivityMetricResponse,
    (request: metric_pb.ListActivityMetricRequest) => {
      return request.serializeBinary();
    },
    metric_pb.ListActivityMetricResponse.deserializeBinary
  );

  listActivityMetric(
    request: metric_pb.ListActivityMetricRequest,
    metadata?: grpcWeb.Metadata | null): Promise<metric_pb.ListActivityMetricResponse>;

  listActivityMetric(
    request: metric_pb.ListActivityMetricRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: metric_pb.ListActivityMetricResponse) => void): grpcWeb.ClientReadableStream<metric_pb.ListActivityMetricResponse>;

  listActivityMetric(
    request: metric_pb.ListActivityMetricRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: metric_pb.ListActivityMetricResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ActivityMetricService/ListActivityMetric',
        request,
        metadata || {},
        this.methodDescriptorListActivityMetric,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ActivityMetricService/ListActivityMetric',
    request,
    metadata || {},
    this.methodDescriptorListActivityMetric);
  }

}

