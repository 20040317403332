/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: types.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as types_pb from './types_pb'; // proto import: "types.proto"


export class TypesServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateTypes = new grpcWeb.MethodDescriptor(
    '/proto.TypesService/CreateTypes',
    grpcWeb.MethodType.UNARY,
    types_pb.CreateTypesRequest,
    types_pb.CreateTypesResponse,
    (request: types_pb.CreateTypesRequest) => {
      return request.serializeBinary();
    },
    types_pb.CreateTypesResponse.deserializeBinary
  );

  createTypes(
    request: types_pb.CreateTypesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<types_pb.CreateTypesResponse>;

  createTypes(
    request: types_pb.CreateTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: types_pb.CreateTypesResponse) => void): grpcWeb.ClientReadableStream<types_pb.CreateTypesResponse>;

  createTypes(
    request: types_pb.CreateTypesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: types_pb.CreateTypesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.TypesService/CreateTypes',
        request,
        metadata || {},
        this.methodDescriptorCreateTypes,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.TypesService/CreateTypes',
    request,
    metadata || {},
    this.methodDescriptorCreateTypes);
  }

  methodDescriptorUpdateTypes = new grpcWeb.MethodDescriptor(
    '/proto.TypesService/UpdateTypes',
    grpcWeb.MethodType.UNARY,
    types_pb.UpdateTypesRequest,
    types_pb.UpdateTypesResponse,
    (request: types_pb.UpdateTypesRequest) => {
      return request.serializeBinary();
    },
    types_pb.UpdateTypesResponse.deserializeBinary
  );

  updateTypes(
    request: types_pb.UpdateTypesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<types_pb.UpdateTypesResponse>;

  updateTypes(
    request: types_pb.UpdateTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: types_pb.UpdateTypesResponse) => void): grpcWeb.ClientReadableStream<types_pb.UpdateTypesResponse>;

  updateTypes(
    request: types_pb.UpdateTypesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: types_pb.UpdateTypesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.TypesService/UpdateTypes',
        request,
        metadata || {},
        this.methodDescriptorUpdateTypes,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.TypesService/UpdateTypes',
    request,
    metadata || {},
    this.methodDescriptorUpdateTypes);
  }

  methodDescriptorDeleteTypes = new grpcWeb.MethodDescriptor(
    '/proto.TypesService/DeleteTypes',
    grpcWeb.MethodType.UNARY,
    types_pb.DeleteTypesRequest,
    types_pb.DeleteTypesResponse,
    (request: types_pb.DeleteTypesRequest) => {
      return request.serializeBinary();
    },
    types_pb.DeleteTypesResponse.deserializeBinary
  );

  deleteTypes(
    request: types_pb.DeleteTypesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<types_pb.DeleteTypesResponse>;

  deleteTypes(
    request: types_pb.DeleteTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: types_pb.DeleteTypesResponse) => void): grpcWeb.ClientReadableStream<types_pb.DeleteTypesResponse>;

  deleteTypes(
    request: types_pb.DeleteTypesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: types_pb.DeleteTypesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.TypesService/DeleteTypes',
        request,
        metadata || {},
        this.methodDescriptorDeleteTypes,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.TypesService/DeleteTypes',
    request,
    metadata || {},
    this.methodDescriptorDeleteTypes);
  }

  methodDescriptorListTypes = new grpcWeb.MethodDescriptor(
    '/proto.TypesService/ListTypes',
    grpcWeb.MethodType.UNARY,
    types_pb.ListTypesRequest,
    types_pb.ListTypesResponse,
    (request: types_pb.ListTypesRequest) => {
      return request.serializeBinary();
    },
    types_pb.ListTypesResponse.deserializeBinary
  );

  listTypes(
    request: types_pb.ListTypesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<types_pb.ListTypesResponse>;

  listTypes(
    request: types_pb.ListTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: types_pb.ListTypesResponse) => void): grpcWeb.ClientReadableStream<types_pb.ListTypesResponse>;

  listTypes(
    request: types_pb.ListTypesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: types_pb.ListTypesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.TypesService/ListTypes',
        request,
        metadata || {},
        this.methodDescriptorListTypes,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.TypesService/ListTypes',
    request,
    metadata || {},
    this.methodDescriptorListTypes);
  }

}

