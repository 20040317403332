/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: period.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as period_pb from './period_pb'; // proto import: "period.proto"


export class ActivityPeriodServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateActivityPeriod = new grpcWeb.MethodDescriptor(
    '/proto.ActivityPeriodService/CreateActivityPeriod',
    grpcWeb.MethodType.UNARY,
    period_pb.CreateActivityPeriodRequest,
    period_pb.CreateActivityPeriodResponse,
    (request: period_pb.CreateActivityPeriodRequest) => {
      return request.serializeBinary();
    },
    period_pb.CreateActivityPeriodResponse.deserializeBinary
  );

  createActivityPeriod(
    request: period_pb.CreateActivityPeriodRequest,
    metadata?: grpcWeb.Metadata | null): Promise<period_pb.CreateActivityPeriodResponse>;

  createActivityPeriod(
    request: period_pb.CreateActivityPeriodRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: period_pb.CreateActivityPeriodResponse) => void): grpcWeb.ClientReadableStream<period_pb.CreateActivityPeriodResponse>;

  createActivityPeriod(
    request: period_pb.CreateActivityPeriodRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: period_pb.CreateActivityPeriodResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ActivityPeriodService/CreateActivityPeriod',
        request,
        metadata || {},
        this.methodDescriptorCreateActivityPeriod,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ActivityPeriodService/CreateActivityPeriod',
    request,
    metadata || {},
    this.methodDescriptorCreateActivityPeriod);
  }

  methodDescriptorUpdateActivityPeriod = new grpcWeb.MethodDescriptor(
    '/proto.ActivityPeriodService/UpdateActivityPeriod',
    grpcWeb.MethodType.UNARY,
    period_pb.UpdateActivityPeriodRequest,
    period_pb.UpdateActivityPeriodResponse,
    (request: period_pb.UpdateActivityPeriodRequest) => {
      return request.serializeBinary();
    },
    period_pb.UpdateActivityPeriodResponse.deserializeBinary
  );

  updateActivityPeriod(
    request: period_pb.UpdateActivityPeriodRequest,
    metadata?: grpcWeb.Metadata | null): Promise<period_pb.UpdateActivityPeriodResponse>;

  updateActivityPeriod(
    request: period_pb.UpdateActivityPeriodRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: period_pb.UpdateActivityPeriodResponse) => void): grpcWeb.ClientReadableStream<period_pb.UpdateActivityPeriodResponse>;

  updateActivityPeriod(
    request: period_pb.UpdateActivityPeriodRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: period_pb.UpdateActivityPeriodResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ActivityPeriodService/UpdateActivityPeriod',
        request,
        metadata || {},
        this.methodDescriptorUpdateActivityPeriod,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ActivityPeriodService/UpdateActivityPeriod',
    request,
    metadata || {},
    this.methodDescriptorUpdateActivityPeriod);
  }

  methodDescriptorDeleteActivityPeriod = new grpcWeb.MethodDescriptor(
    '/proto.ActivityPeriodService/DeleteActivityPeriod',
    grpcWeb.MethodType.UNARY,
    period_pb.DeleteActivityPeriodRequest,
    period_pb.DeleteActivityPeriodResponse,
    (request: period_pb.DeleteActivityPeriodRequest) => {
      return request.serializeBinary();
    },
    period_pb.DeleteActivityPeriodResponse.deserializeBinary
  );

  deleteActivityPeriod(
    request: period_pb.DeleteActivityPeriodRequest,
    metadata?: grpcWeb.Metadata | null): Promise<period_pb.DeleteActivityPeriodResponse>;

  deleteActivityPeriod(
    request: period_pb.DeleteActivityPeriodRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: period_pb.DeleteActivityPeriodResponse) => void): grpcWeb.ClientReadableStream<period_pb.DeleteActivityPeriodResponse>;

  deleteActivityPeriod(
    request: period_pb.DeleteActivityPeriodRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: period_pb.DeleteActivityPeriodResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ActivityPeriodService/DeleteActivityPeriod',
        request,
        metadata || {},
        this.methodDescriptorDeleteActivityPeriod,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ActivityPeriodService/DeleteActivityPeriod',
    request,
    metadata || {},
    this.methodDescriptorDeleteActivityPeriod);
  }

  methodDescriptorListActivityPeriod = new grpcWeb.MethodDescriptor(
    '/proto.ActivityPeriodService/ListActivityPeriod',
    grpcWeb.MethodType.UNARY,
    period_pb.ListActivityPeriodRequest,
    period_pb.ListActivityPeriodResponse,
    (request: period_pb.ListActivityPeriodRequest) => {
      return request.serializeBinary();
    },
    period_pb.ListActivityPeriodResponse.deserializeBinary
  );

  listActivityPeriod(
    request: period_pb.ListActivityPeriodRequest,
    metadata?: grpcWeb.Metadata | null): Promise<period_pb.ListActivityPeriodResponse>;

  listActivityPeriod(
    request: period_pb.ListActivityPeriodRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: period_pb.ListActivityPeriodResponse) => void): grpcWeb.ClientReadableStream<period_pb.ListActivityPeriodResponse>;

  listActivityPeriod(
    request: period_pb.ListActivityPeriodRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: period_pb.ListActivityPeriodResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ActivityPeriodService/ListActivityPeriod',
        request,
        metadata || {},
        this.methodDescriptorListActivityPeriod,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ActivityPeriodService/ListActivityPeriod',
    request,
    metadata || {},
    this.methodDescriptorListActivityPeriod);
  }

}

