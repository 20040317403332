import { AuthApi } from "@/components/modules/users/services/api/AuthApi";
import * as grpcWeb from 'grpc-web';
import Notify from 'quasar/src/plugins/notify/Notify.js';;
import store from "@/store";

interface UnaryInterceptor<REQ, RESP> {
    intercept(
        request: grpcWeb.Request<REQ, RESP>,
        invoker: (
            request: grpcWeb.Request<REQ, RESP>
        ) => Promise<grpcWeb.UnaryResponse<REQ, RESP>>,
        numRetries: number
    ): Promise<grpcWeb.UnaryResponse<REQ, RESP>>
}

const authApi = new AuthApi()

export class RefreshTokenInterceptor implements UnaryInterceptor<any, any>
{
    async intercept(
        request: grpcWeb.Request<any, any>,
        invoker: (request: grpcWeb.Request<any, any>) => any,
        numRetries = 2
    ) {
        return await invoker(request)
            .then((response: grpcWeb.UnaryResponse<any, any>) => {
                return response
            })
            .catch(async (err: any) => {
                if (numRetries <= 1) throw err

                if (err.message.match('refresh-токен не найден')?.[0]) {
                    Notify.create({
                        message: 'Сессия устарела,перезайдите в аккаунт',
                        type: 'negative',
                        timeout: 0,
                        actions: [{ icon: 'close', color: 'white', dense: true }],
                    })

                    await store.dispatch('logout')

                    throw err
                }

                if (err.message.match('access token is invalid')?.[0]) {
                    if (authApi.refreshRequest) {
                        const response = await authApi.refreshRequest

                        request.getMetadata().Authorization = response.getAccessToken()
                        return await this.intercept(request, invoker, numRetries - 1)
                    }

                    const refreshKey = localStorage.getItem('refresh-key')

                    if (!refreshKey) throw err

                    return await authApi
                        .checkRefreshToken({
                            refreshToken: refreshKey
                        })
                        .then(async (res) => {
                            console.log('INTERCEPT RES', res)
                            if(!res?.getAccessToken?.()) {
                                Notify.create({
                                    message: 'Сессия устарела,перезайдите в аккаунт',
                                    type: 'negative',
                                    timeout: 0,
                                    actions: [{ icon: 'close', color: 'white', dense: true }],
                                })

                                await store.dispatch('logout')

                                throw err
                            }

                            request.getMetadata().Authorization = res.getAccessToken()


                            return await this.intercept(request, invoker, numRetries - 1)
                        })
                }

                throw err
            })
    }
}