// source: accounts.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.proto.Accounts', null, global);
goog.exportSymbol('proto.proto.AccountsFilter', null, global);
goog.exportSymbol('proto.proto.AccountsFilterItem', null, global);
goog.exportSymbol('proto.proto.AccountsPagination', null, global);
goog.exportSymbol('proto.proto.AccountsSort', null, global);
goog.exportSymbol('proto.proto.ExportListAccountsResponse', null, global);
goog.exportSymbol('proto.proto.ItemAccountsRequest', null, global);
goog.exportSymbol('proto.proto.ItemAccountsResponse', null, global);
goog.exportSymbol('proto.proto.ListAccountsRequest', null, global);
goog.exportSymbol('proto.proto.ListAccountsResponse', null, global);
goog.exportSymbol('proto.proto.LoadPersonalListFromFileRequest', null, global);
goog.exportSymbol('proto.proto.LoadPersonalListFromFileResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.Accounts = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.Accounts.repeatedFields_, null);
};
goog.inherits(proto.proto.Accounts, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.Accounts.displayName = 'proto.proto.Accounts';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AccountsFilterItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AccountsFilterItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AccountsFilterItem.displayName = 'proto.proto.AccountsFilterItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AccountsFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.AccountsFilter.repeatedFields_, null);
};
goog.inherits(proto.proto.AccountsFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AccountsFilter.displayName = 'proto.proto.AccountsFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AccountsSort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AccountsSort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AccountsSort.displayName = 'proto.proto.AccountsSort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AccountsPagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AccountsPagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AccountsPagination.displayName = 'proto.proto.AccountsPagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListAccountsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListAccountsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListAccountsRequest.displayName = 'proto.proto.ListAccountsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListAccountsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListAccountsResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListAccountsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListAccountsResponse.displayName = 'proto.proto.ListAccountsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ItemAccountsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ItemAccountsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ItemAccountsRequest.displayName = 'proto.proto.ItemAccountsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ItemAccountsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ItemAccountsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ItemAccountsResponse.displayName = 'proto.proto.ItemAccountsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.LoadPersonalListFromFileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.LoadPersonalListFromFileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.LoadPersonalListFromFileRequest.displayName = 'proto.proto.LoadPersonalListFromFileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.LoadPersonalListFromFileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.LoadPersonalListFromFileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.LoadPersonalListFromFileResponse.displayName = 'proto.proto.LoadPersonalListFromFileResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ExportListAccountsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ExportListAccountsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ExportListAccountsResponse.displayName = 'proto.proto.ExportListAccountsResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.Accounts.repeatedFields_ = [45,46,47];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.Accounts.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.Accounts.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.Accounts} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Accounts.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountGuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ssoId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    typeId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    birthday: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sex: jspb.Message.getFieldWithDefault(msg, 5, ""),
    points: jspb.Message.getFieldWithDefault(msg, 6, 0),
    pointsEarned: jspb.Message.getFieldWithDefault(msg, 7, 0),
    pointsSpent: jspb.Message.getFieldWithDefault(msg, 8, 0),
    pointsCity: jspb.Message.getFieldWithDefault(msg, 9, 0),
    pointsEarnedCity: jspb.Message.getFieldWithDefault(msg, 10, 0),
    pointsSpentCity: jspb.Message.getFieldWithDefault(msg, 11, 0),
    pointsPrize: jspb.Message.getFieldWithDefault(msg, 12, 0),
    pointsEarnedPrize: jspb.Message.getFieldWithDefault(msg, 13, 0),
    pointsSpentPrize: jspb.Message.getFieldWithDefault(msg, 14, 0),
    familyStatus: jspb.Message.getFieldWithDefault(msg, 15, ""),
    numChild: jspb.Message.getFieldWithDefault(msg, 16, 0),
    social: jspb.Message.getFieldWithDefault(msg, 17, ""),
    region: jspb.Message.getFieldWithDefault(msg, 18, ""),
    district: jspb.Message.getFieldWithDefault(msg, 19, ""),
    address: jspb.Message.getFieldWithDefault(msg, 20, ""),
    cntProjects: jspb.Message.getFieldWithDefault(msg, 21, 0),
    cntActivity: jspb.Message.getFieldWithDefault(msg, 22, 0),
    lastActivityDate: jspb.Message.getFieldWithDefault(msg, 23, ""),
    loyalty: jspb.Message.getFieldWithDefault(msg, 24, ""),
    usefulness: jspb.Message.getFieldWithDefault(msg, 25, ""),
    interest: jspb.Message.getFieldWithDefault(msg, 26, ""),
    isAg: jspb.Message.getFieldWithDefault(msg, 27, 0),
    isEd: jspb.Message.getFieldWithDefault(msg, 28, 0),
    isGi: jspb.Message.getFieldWithDefault(msg, 29, 0),
    isGz: jspb.Message.getFieldWithDefault(msg, 30, 0),
    isNg: jspb.Message.getFieldWithDefault(msg, 31, 0),
    isMp: jspb.Message.getFieldWithDefault(msg, 32, 0),
    isAgc: jspb.Message.getFieldWithDefault(msg, 33, 0),
    regdateAg: jspb.Message.getFieldWithDefault(msg, 34, ""),
    regdateEd: jspb.Message.getFieldWithDefault(msg, 35, ""),
    regdateGi: jspb.Message.getFieldWithDefault(msg, 36, ""),
    regdateGz: jspb.Message.getFieldWithDefault(msg, 37, ""),
    regdateNg: jspb.Message.getFieldWithDefault(msg, 38, ""),
    regdateMp: jspb.Message.getFieldWithDefault(msg, 39, ""),
    regdateAgc: jspb.Message.getFieldWithDefault(msg, 40, ""),
    typeDescr: jspb.Message.getFieldWithDefault(msg, 41, ""),
    isBlocked: jspb.Message.getFieldWithDefault(msg, 42, 0),
    activity: jspb.Message.getFieldWithDefault(msg, 43, ""),
    messagesGroup: jspb.Message.getFieldWithDefault(msg, 44, ""),
    messagesClassesList: (f = jspb.Message.getRepeatedField(msg, 45)) == null ? undefined : f,
    tagsList: (f = jspb.Message.getRepeatedField(msg, 46)) == null ? undefined : f,
    tagsIdsList: (f = jspb.Message.getRepeatedField(msg, 47)) == null ? undefined : f,
    firstActivityDate: jspb.Message.getFieldWithDefault(msg, 48, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.Accounts}
 */
proto.proto.Accounts.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.Accounts;
  return proto.proto.Accounts.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.Accounts} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.Accounts}
 */
proto.proto.Accounts.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountGuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSsoId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTypeId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBirthday(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSex(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPoints(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPointsEarned(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPointsSpent(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPointsCity(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPointsEarnedCity(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPointsSpentCity(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPointsPrize(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPointsEarnedPrize(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPointsSpentPrize(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setFamilyStatus(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumChild(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setSocial(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setDistrict(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCntProjects(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCntActivity(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastActivityDate(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoyalty(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsefulness(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setInterest(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIsAg(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIsEd(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIsGi(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIsGz(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIsNg(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIsMp(value);
      break;
    case 33:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIsAgc(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegdateAg(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegdateEd(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegdateGi(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegdateGz(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegdateNg(value);
      break;
    case 39:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegdateMp(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegdateAgc(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setTypeDescr(value);
      break;
    case 42:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIsBlocked(value);
      break;
    case 43:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivity(value);
      break;
    case 44:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessagesGroup(value);
      break;
    case 45:
      var value = /** @type {string} */ (reader.readString());
      msg.addMessagesClasses(value);
      break;
    case 46:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    case 47:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTagsIds(values[i]);
      }
      break;
    case 48:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstActivityDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.Accounts.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.Accounts.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.Accounts} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Accounts.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountGuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSsoId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTypeId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getBirthday();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSex();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPoints();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getPointsEarned();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getPointsSpent();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getPointsCity();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getPointsEarnedCity();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getPointsSpentCity();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getPointsPrize();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getPointsEarnedPrize();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getPointsSpentPrize();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getFamilyStatus();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getNumChild();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getSocial();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getDistrict();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getCntProjects();
  if (f !== 0) {
    writer.writeInt64(
      21,
      f
    );
  }
  f = message.getCntActivity();
  if (f !== 0) {
    writer.writeInt64(
      22,
      f
    );
  }
  f = message.getLastActivityDate();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getLoyalty();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getUsefulness();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getInterest();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getIsAg();
  if (f !== 0) {
    writer.writeInt64(
      27,
      f
    );
  }
  f = message.getIsEd();
  if (f !== 0) {
    writer.writeInt64(
      28,
      f
    );
  }
  f = message.getIsGi();
  if (f !== 0) {
    writer.writeInt64(
      29,
      f
    );
  }
  f = message.getIsGz();
  if (f !== 0) {
    writer.writeInt64(
      30,
      f
    );
  }
  f = message.getIsNg();
  if (f !== 0) {
    writer.writeInt64(
      31,
      f
    );
  }
  f = message.getIsMp();
  if (f !== 0) {
    writer.writeInt64(
      32,
      f
    );
  }
  f = message.getIsAgc();
  if (f !== 0) {
    writer.writeInt64(
      33,
      f
    );
  }
  f = message.getRegdateAg();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getRegdateEd();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getRegdateGi();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getRegdateGz();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getRegdateNg();
  if (f.length > 0) {
    writer.writeString(
      38,
      f
    );
  }
  f = message.getRegdateMp();
  if (f.length > 0) {
    writer.writeString(
      39,
      f
    );
  }
  f = message.getRegdateAgc();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getTypeDescr();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
  f = message.getIsBlocked();
  if (f !== 0) {
    writer.writeInt64(
      42,
      f
    );
  }
  f = message.getActivity();
  if (f.length > 0) {
    writer.writeString(
      43,
      f
    );
  }
  f = message.getMessagesGroup();
  if (f.length > 0) {
    writer.writeString(
      44,
      f
    );
  }
  f = message.getMessagesClassesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      45,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      46,
      f
    );
  }
  f = message.getTagsIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      47,
      f
    );
  }
  f = message.getFirstActivityDate();
  if (f.length > 0) {
    writer.writeString(
      48,
      f
    );
  }
};


/**
 * optional string account_guid = 1;
 * @return {string}
 */
proto.proto.Accounts.prototype.getAccountGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setAccountGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sso_id = 2;
 * @return {string}
 */
proto.proto.Accounts.prototype.getSsoId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setSsoId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 type_id = 3;
 * @return {number}
 */
proto.proto.Accounts.prototype.getTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string birthday = 4;
 * @return {string}
 */
proto.proto.Accounts.prototype.getBirthday = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setBirthday = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string sex = 5;
 * @return {string}
 */
proto.proto.Accounts.prototype.getSex = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setSex = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 points = 6;
 * @return {number}
 */
proto.proto.Accounts.prototype.getPoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setPoints = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 points_earned = 7;
 * @return {number}
 */
proto.proto.Accounts.prototype.getPointsEarned = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setPointsEarned = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 points_spent = 8;
 * @return {number}
 */
proto.proto.Accounts.prototype.getPointsSpent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setPointsSpent = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 points_city = 9;
 * @return {number}
 */
proto.proto.Accounts.prototype.getPointsCity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setPointsCity = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 points_earned_city = 10;
 * @return {number}
 */
proto.proto.Accounts.prototype.getPointsEarnedCity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setPointsEarnedCity = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 points_spent_city = 11;
 * @return {number}
 */
proto.proto.Accounts.prototype.getPointsSpentCity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setPointsSpentCity = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 points_prize = 12;
 * @return {number}
 */
proto.proto.Accounts.prototype.getPointsPrize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setPointsPrize = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 points_earned_prize = 13;
 * @return {number}
 */
proto.proto.Accounts.prototype.getPointsEarnedPrize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setPointsEarnedPrize = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 points_spent_prize = 14;
 * @return {number}
 */
proto.proto.Accounts.prototype.getPointsSpentPrize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setPointsSpentPrize = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string family_status = 15;
 * @return {string}
 */
proto.proto.Accounts.prototype.getFamilyStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setFamilyStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional int64 num_child = 16;
 * @return {number}
 */
proto.proto.Accounts.prototype.getNumChild = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setNumChild = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string social = 17;
 * @return {string}
 */
proto.proto.Accounts.prototype.getSocial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setSocial = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string region = 18;
 * @return {string}
 */
proto.proto.Accounts.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string district = 19;
 * @return {string}
 */
proto.proto.Accounts.prototype.getDistrict = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setDistrict = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string address = 20;
 * @return {string}
 */
proto.proto.Accounts.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional int64 cnt_projects = 21;
 * @return {number}
 */
proto.proto.Accounts.prototype.getCntProjects = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setCntProjects = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional int64 cnt_activity = 22;
 * @return {number}
 */
proto.proto.Accounts.prototype.getCntActivity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setCntActivity = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional string last_activity_date = 23;
 * @return {string}
 */
proto.proto.Accounts.prototype.getLastActivityDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setLastActivityDate = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string loyalty = 24;
 * @return {string}
 */
proto.proto.Accounts.prototype.getLoyalty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setLoyalty = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string usefulness = 25;
 * @return {string}
 */
proto.proto.Accounts.prototype.getUsefulness = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setUsefulness = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string interest = 26;
 * @return {string}
 */
proto.proto.Accounts.prototype.getInterest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setInterest = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional int64 is_ag = 27;
 * @return {number}
 */
proto.proto.Accounts.prototype.getIsAg = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setIsAg = function(value) {
  return jspb.Message.setProto3IntField(this, 27, value);
};


/**
 * optional int64 is_ed = 28;
 * @return {number}
 */
proto.proto.Accounts.prototype.getIsEd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setIsEd = function(value) {
  return jspb.Message.setProto3IntField(this, 28, value);
};


/**
 * optional int64 is_gi = 29;
 * @return {number}
 */
proto.proto.Accounts.prototype.getIsGi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setIsGi = function(value) {
  return jspb.Message.setProto3IntField(this, 29, value);
};


/**
 * optional int64 is_gz = 30;
 * @return {number}
 */
proto.proto.Accounts.prototype.getIsGz = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setIsGz = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};


/**
 * optional int64 is_ng = 31;
 * @return {number}
 */
proto.proto.Accounts.prototype.getIsNg = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 31, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setIsNg = function(value) {
  return jspb.Message.setProto3IntField(this, 31, value);
};


/**
 * optional int64 is_mp = 32;
 * @return {number}
 */
proto.proto.Accounts.prototype.getIsMp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setIsMp = function(value) {
  return jspb.Message.setProto3IntField(this, 32, value);
};


/**
 * optional int64 is_agc = 33;
 * @return {number}
 */
proto.proto.Accounts.prototype.getIsAgc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 33, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setIsAgc = function(value) {
  return jspb.Message.setProto3IntField(this, 33, value);
};


/**
 * optional string regdate_ag = 34;
 * @return {string}
 */
proto.proto.Accounts.prototype.getRegdateAg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setRegdateAg = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional string regdate_ed = 35;
 * @return {string}
 */
proto.proto.Accounts.prototype.getRegdateEd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setRegdateEd = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional string regdate_gi = 36;
 * @return {string}
 */
proto.proto.Accounts.prototype.getRegdateGi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setRegdateGi = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional string regdate_gz = 37;
 * @return {string}
 */
proto.proto.Accounts.prototype.getRegdateGz = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setRegdateGz = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * optional string regdate_ng = 38;
 * @return {string}
 */
proto.proto.Accounts.prototype.getRegdateNg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setRegdateNg = function(value) {
  return jspb.Message.setProto3StringField(this, 38, value);
};


/**
 * optional string regdate_mp = 39;
 * @return {string}
 */
proto.proto.Accounts.prototype.getRegdateMp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 39, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setRegdateMp = function(value) {
  return jspb.Message.setProto3StringField(this, 39, value);
};


/**
 * optional string regdate_agc = 40;
 * @return {string}
 */
proto.proto.Accounts.prototype.getRegdateAgc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setRegdateAgc = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional string type_descr = 41;
 * @return {string}
 */
proto.proto.Accounts.prototype.getTypeDescr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setTypeDescr = function(value) {
  return jspb.Message.setProto3StringField(this, 41, value);
};


/**
 * optional int64 is_blocked = 42;
 * @return {number}
 */
proto.proto.Accounts.prototype.getIsBlocked = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 42, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setIsBlocked = function(value) {
  return jspb.Message.setProto3IntField(this, 42, value);
};


/**
 * optional string activity = 43;
 * @return {string}
 */
proto.proto.Accounts.prototype.getActivity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 43, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setActivity = function(value) {
  return jspb.Message.setProto3StringField(this, 43, value);
};


/**
 * optional string messages_group = 44;
 * @return {string}
 */
proto.proto.Accounts.prototype.getMessagesGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 44, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setMessagesGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 44, value);
};


/**
 * repeated string messages_classes = 45;
 * @return {!Array<string>}
 */
proto.proto.Accounts.prototype.getMessagesClassesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 45));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setMessagesClassesList = function(value) {
  return jspb.Message.setField(this, 45, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.addMessagesClasses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 45, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.clearMessagesClassesList = function() {
  return this.setMessagesClassesList([]);
};


/**
 * repeated string tags = 46;
 * @return {!Array<string>}
 */
proto.proto.Accounts.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 46));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 46, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 46, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * repeated int64 tags_ids = 47;
 * @return {!Array<number>}
 */
proto.proto.Accounts.prototype.getTagsIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 47));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setTagsIdsList = function(value) {
  return jspb.Message.setField(this, 47, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.addTagsIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 47, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.clearTagsIdsList = function() {
  return this.setTagsIdsList([]);
};


/**
 * optional string first_activity_date = 48;
 * @return {string}
 */
proto.proto.Accounts.prototype.getFirstActivityDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 48, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Accounts} returns this
 */
proto.proto.Accounts.prototype.setFirstActivityDate = function(value) {
  return jspb.Message.setProto3StringField(this, 48, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AccountsFilterItem.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AccountsFilterItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AccountsFilterItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AccountsFilterItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operand: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    or: (f = msg.getOr()) && proto.proto.AccountsFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AccountsFilterItem}
 */
proto.proto.AccountsFilterItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AccountsFilterItem;
  return proto.proto.AccountsFilterItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AccountsFilterItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AccountsFilterItem}
 */
proto.proto.AccountsFilterItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperand(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = new proto.proto.AccountsFilter;
      reader.readMessage(value,proto.proto.AccountsFilter.deserializeBinaryFromReader);
      msg.setOr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AccountsFilterItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AccountsFilterItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AccountsFilterItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AccountsFilterItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperand();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOr();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.proto.AccountsFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field_name = 1;
 * @return {string}
 */
proto.proto.AccountsFilterItem.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountsFilterItem} returns this
 */
proto.proto.AccountsFilterItem.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string operand = 2;
 * @return {string}
 */
proto.proto.AccountsFilterItem.prototype.getOperand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountsFilterItem} returns this
 */
proto.proto.AccountsFilterItem.prototype.setOperand = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.proto.AccountsFilterItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountsFilterItem} returns this
 */
proto.proto.AccountsFilterItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional AccountsFilter or = 4;
 * @return {?proto.proto.AccountsFilter}
 */
proto.proto.AccountsFilterItem.prototype.getOr = function() {
  return /** @type{?proto.proto.AccountsFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.AccountsFilter, 4));
};


/**
 * @param {?proto.proto.AccountsFilter|undefined} value
 * @return {!proto.proto.AccountsFilterItem} returns this
*/
proto.proto.AccountsFilterItem.prototype.setOr = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AccountsFilterItem} returns this
 */
proto.proto.AccountsFilterItem.prototype.clearOr = function() {
  return this.setOr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AccountsFilterItem.prototype.hasOr = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.AccountsFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AccountsFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AccountsFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AccountsFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AccountsFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.AccountsFilterItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AccountsFilter}
 */
proto.proto.AccountsFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AccountsFilter;
  return proto.proto.AccountsFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AccountsFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AccountsFilter}
 */
proto.proto.AccountsFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AccountsFilterItem;
      reader.readMessage(value,proto.proto.AccountsFilterItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AccountsFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AccountsFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AccountsFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AccountsFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.AccountsFilterItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AccountsFilterItem items = 1;
 * @return {!Array<!proto.proto.AccountsFilterItem>}
 */
proto.proto.AccountsFilter.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.AccountsFilterItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.AccountsFilterItem, 1));
};


/**
 * @param {!Array<!proto.proto.AccountsFilterItem>} value
 * @return {!proto.proto.AccountsFilter} returns this
*/
proto.proto.AccountsFilter.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.AccountsFilterItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.AccountsFilterItem}
 */
proto.proto.AccountsFilter.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.AccountsFilterItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.AccountsFilter} returns this
 */
proto.proto.AccountsFilter.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AccountsSort.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AccountsSort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AccountsSort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AccountsSort.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    exp: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AccountsSort}
 */
proto.proto.AccountsSort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AccountsSort;
  return proto.proto.AccountsSort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AccountsSort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AccountsSort}
 */
proto.proto.AccountsSort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AccountsSort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AccountsSort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AccountsSort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AccountsSort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.proto.AccountsSort.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountsSort} returns this
 */
proto.proto.AccountsSort.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string exp = 2;
 * @return {string}
 */
proto.proto.AccountsSort.prototype.getExp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountsSort} returns this
 */
proto.proto.AccountsSort.prototype.setExp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AccountsPagination.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AccountsPagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AccountsPagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AccountsPagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pages: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cnt: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AccountsPagination}
 */
proto.proto.AccountsPagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AccountsPagination;
  return proto.proto.AccountsPagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AccountsPagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AccountsPagination}
 */
proto.proto.AccountsPagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPages(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCnt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AccountsPagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AccountsPagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AccountsPagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AccountsPagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPages();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCnt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 page = 1;
 * @return {number}
 */
proto.proto.AccountsPagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AccountsPagination} returns this
 */
proto.proto.AccountsPagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 limit = 2;
 * @return {number}
 */
proto.proto.AccountsPagination.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AccountsPagination} returns this
 */
proto.proto.AccountsPagination.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 pages = 3;
 * @return {number}
 */
proto.proto.AccountsPagination.prototype.getPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AccountsPagination} returns this
 */
proto.proto.AccountsPagination.prototype.setPages = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 cnt = 4;
 * @return {number}
 */
proto.proto.AccountsPagination.prototype.getCnt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AccountsPagination} returns this
 */
proto.proto.AccountsPagination.prototype.setCnt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListAccountsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListAccountsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListAccountsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAccountsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.AccountsFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.AccountsSort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.proto.AccountsPagination.toObject(includeInstance, f),
    personalList: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListAccountsRequest}
 */
proto.proto.ListAccountsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListAccountsRequest;
  return proto.proto.ListAccountsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListAccountsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListAccountsRequest}
 */
proto.proto.ListAccountsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AccountsFilter;
      reader.readMessage(value,proto.proto.AccountsFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.AccountsSort;
      reader.readMessage(value,proto.proto.AccountsSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var value = new proto.proto.AccountsPagination;
      reader.readMessage(value,proto.proto.AccountsPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPersonalList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListAccountsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListAccountsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListAccountsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAccountsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.AccountsFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.AccountsSort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.AccountsPagination.serializeBinaryToWriter
    );
  }
  f = message.getPersonalList();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional AccountsFilter filter = 1;
 * @return {?proto.proto.AccountsFilter}
 */
proto.proto.ListAccountsRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.AccountsFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.AccountsFilter, 1));
};


/**
 * @param {?proto.proto.AccountsFilter|undefined} value
 * @return {!proto.proto.ListAccountsRequest} returns this
*/
proto.proto.ListAccountsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAccountsRequest} returns this
 */
proto.proto.ListAccountsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAccountsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AccountsSort sort = 2;
 * @return {?proto.proto.AccountsSort}
 */
proto.proto.ListAccountsRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.AccountsSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.AccountsSort, 2));
};


/**
 * @param {?proto.proto.AccountsSort|undefined} value
 * @return {!proto.proto.ListAccountsRequest} returns this
*/
proto.proto.ListAccountsRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAccountsRequest} returns this
 */
proto.proto.ListAccountsRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAccountsRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AccountsPagination pagination = 3;
 * @return {?proto.proto.AccountsPagination}
 */
proto.proto.ListAccountsRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.AccountsPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.AccountsPagination, 3));
};


/**
 * @param {?proto.proto.AccountsPagination|undefined} value
 * @return {!proto.proto.ListAccountsRequest} returns this
*/
proto.proto.ListAccountsRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAccountsRequest} returns this
 */
proto.proto.ListAccountsRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAccountsRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool personal_list = 4;
 * @return {boolean}
 */
proto.proto.ListAccountsRequest.prototype.getPersonalList = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ListAccountsRequest} returns this
 */
proto.proto.ListAccountsRequest.prototype.setPersonalList = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListAccountsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListAccountsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListAccountsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListAccountsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAccountsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.Accounts.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListAccountsRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListAccountsResponse}
 */
proto.proto.ListAccountsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListAccountsResponse;
  return proto.proto.ListAccountsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListAccountsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListAccountsResponse}
 */
proto.proto.ListAccountsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Accounts;
      reader.readMessage(value,proto.proto.Accounts.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListAccountsRequest;
      reader.readMessage(value,proto.proto.ListAccountsRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListAccountsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListAccountsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListAccountsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAccountsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.Accounts.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListAccountsRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Accounts items = 1;
 * @return {!Array<!proto.proto.Accounts>}
 */
proto.proto.ListAccountsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.Accounts>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.Accounts, 1));
};


/**
 * @param {!Array<!proto.proto.Accounts>} value
 * @return {!proto.proto.ListAccountsResponse} returns this
*/
proto.proto.ListAccountsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.Accounts=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.Accounts}
 */
proto.proto.ListAccountsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.Accounts, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListAccountsResponse} returns this
 */
proto.proto.ListAccountsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListAccountsRequest params = 2;
 * @return {?proto.proto.ListAccountsRequest}
 */
proto.proto.ListAccountsResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListAccountsRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListAccountsRequest, 2));
};


/**
 * @param {?proto.proto.ListAccountsRequest|undefined} value
 * @return {!proto.proto.ListAccountsResponse} returns this
*/
proto.proto.ListAccountsResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAccountsResponse} returns this
 */
proto.proto.ListAccountsResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAccountsResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ItemAccountsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ItemAccountsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ItemAccountsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ItemAccountsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.AccountsFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ItemAccountsRequest}
 */
proto.proto.ItemAccountsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ItemAccountsRequest;
  return proto.proto.ItemAccountsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ItemAccountsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ItemAccountsRequest}
 */
proto.proto.ItemAccountsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AccountsFilter;
      reader.readMessage(value,proto.proto.AccountsFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ItemAccountsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ItemAccountsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ItemAccountsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ItemAccountsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.AccountsFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional AccountsFilter filter = 1;
 * @return {?proto.proto.AccountsFilter}
 */
proto.proto.ItemAccountsRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.AccountsFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.AccountsFilter, 1));
};


/**
 * @param {?proto.proto.AccountsFilter|undefined} value
 * @return {!proto.proto.ItemAccountsRequest} returns this
*/
proto.proto.ItemAccountsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ItemAccountsRequest} returns this
 */
proto.proto.ItemAccountsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ItemAccountsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ItemAccountsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ItemAccountsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ItemAccountsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ItemAccountsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    item: (f = msg.getItem()) && proto.proto.Accounts.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ItemAccountsResponse}
 */
proto.proto.ItemAccountsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ItemAccountsResponse;
  return proto.proto.ItemAccountsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ItemAccountsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ItemAccountsResponse}
 */
proto.proto.ItemAccountsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Accounts;
      reader.readMessage(value,proto.proto.Accounts.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ItemAccountsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ItemAccountsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ItemAccountsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ItemAccountsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.Accounts.serializeBinaryToWriter
    );
  }
};


/**
 * optional Accounts item = 1;
 * @return {?proto.proto.Accounts}
 */
proto.proto.ItemAccountsResponse.prototype.getItem = function() {
  return /** @type{?proto.proto.Accounts} */ (
    jspb.Message.getWrapperField(this, proto.proto.Accounts, 1));
};


/**
 * @param {?proto.proto.Accounts|undefined} value
 * @return {!proto.proto.ItemAccountsResponse} returns this
*/
proto.proto.ItemAccountsResponse.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ItemAccountsResponse} returns this
 */
proto.proto.ItemAccountsResponse.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ItemAccountsResponse.prototype.hasItem = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.LoadPersonalListFromFileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.LoadPersonalListFromFileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.LoadPersonalListFromFileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LoadPersonalListFromFileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    base64File: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.LoadPersonalListFromFileRequest}
 */
proto.proto.LoadPersonalListFromFileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.LoadPersonalListFromFileRequest;
  return proto.proto.LoadPersonalListFromFileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.LoadPersonalListFromFileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.LoadPersonalListFromFileRequest}
 */
proto.proto.LoadPersonalListFromFileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBase64File(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.LoadPersonalListFromFileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.LoadPersonalListFromFileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.LoadPersonalListFromFileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LoadPersonalListFromFileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase64File();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string base64_file = 1;
 * @return {string}
 */
proto.proto.LoadPersonalListFromFileRequest.prototype.getBase64File = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.LoadPersonalListFromFileRequest} returns this
 */
proto.proto.LoadPersonalListFromFileRequest.prototype.setBase64File = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.LoadPersonalListFromFileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.LoadPersonalListFromFileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.LoadPersonalListFromFileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LoadPersonalListFromFileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.LoadPersonalListFromFileResponse}
 */
proto.proto.LoadPersonalListFromFileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.LoadPersonalListFromFileResponse;
  return proto.proto.LoadPersonalListFromFileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.LoadPersonalListFromFileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.LoadPersonalListFromFileResponse}
 */
proto.proto.LoadPersonalListFromFileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.LoadPersonalListFromFileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.LoadPersonalListFromFileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.LoadPersonalListFromFileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LoadPersonalListFromFileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.proto.LoadPersonalListFromFileResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.LoadPersonalListFromFileResponse} returns this
 */
proto.proto.LoadPersonalListFromFileResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ExportListAccountsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ExportListAccountsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ExportListAccountsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ExportListAccountsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    file: msg.getFile_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ExportListAccountsResponse}
 */
proto.proto.ExportListAccountsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ExportListAccountsResponse;
  return proto.proto.ExportListAccountsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ExportListAccountsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ExportListAccountsResponse}
 */
proto.proto.ExportListAccountsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ExportListAccountsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ExportListAccountsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ExportListAccountsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ExportListAccountsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFile_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes file = 1;
 * @return {!(string|Uint8Array)}
 */
proto.proto.ExportListAccountsResponse.prototype.getFile = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes file = 1;
 * This is a type-conversion wrapper around `getFile()`
 * @return {string}
 */
proto.proto.ExportListAccountsResponse.prototype.getFile_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFile()));
};


/**
 * optional bytes file = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFile()`
 * @return {!Uint8Array}
 */
proto.proto.ExportListAccountsResponse.prototype.getFile_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFile()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.proto.ExportListAccountsResponse} returns this
 */
proto.proto.ExportListAccountsResponse.prototype.setFile = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


goog.object.extend(exports, proto.proto);
module.exports = proto.proto;
