// source: accounts_src.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.proto.AccountSrc', null, global);
goog.exportSymbol('proto.proto.ItemAccountSrcRequest', null, global);
goog.exportSymbol('proto.proto.ItemAccountSrcResponse', null, global);
goog.exportSymbol('proto.proto.ListAccountSrcRequest', null, global);
goog.exportSymbol('proto.proto.ListAccountSrcResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AccountSrc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.AccountSrc.repeatedFields_, null);
};
goog.inherits(proto.proto.AccountSrc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AccountSrc.displayName = 'proto.proto.AccountSrc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListAccountSrcRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListAccountSrcRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListAccountSrcRequest.displayName = 'proto.proto.ListAccountSrcRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListAccountSrcResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListAccountSrcResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListAccountSrcResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListAccountSrcResponse.displayName = 'proto.proto.ListAccountSrcResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ItemAccountSrcRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ItemAccountSrcRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ItemAccountSrcRequest.displayName = 'proto.proto.ItemAccountSrcRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ItemAccountSrcResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ItemAccountSrcResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ItemAccountSrcResponse.displayName = 'proto.proto.ItemAccountSrcResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.AccountSrc.repeatedFields_ = [35];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AccountSrc.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AccountSrc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AccountSrc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AccountSrc.toObject = function(includeInstance, msg) {
  var f, obj = {
    ssoId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    projectId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    regDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sourceCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    typeId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    fullName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    birthday: jspb.Message.getFieldWithDefault(msg, 7, ""),
    sex: jspb.Message.getFieldWithDefault(msg, 8, ""),
    familyStatus: jspb.Message.getFieldWithDefault(msg, 9, ""),
    region: jspb.Message.getFieldWithDefault(msg, 10, ""),
    district: jspb.Message.getFieldWithDefault(msg, 11, ""),
    address: jspb.Message.getFieldWithDefault(msg, 12, ""),
    numChield: jspb.Message.getFieldWithDefault(msg, 13, 0),
    isPublic: jspb.Message.getFieldWithDefault(msg, 14, 0),
    workRegion: jspb.Message.getFieldWithDefault(msg, 15, ""),
    workDistrict: jspb.Message.getFieldWithDefault(msg, 16, ""),
    workAddress: jspb.Message.getFieldWithDefault(msg, 17, ""),
    social: jspb.Message.getFieldWithDefault(msg, 18, ""),
    fill: jspb.Message.getFieldWithDefault(msg, 19, 0),
    liveRegion: jspb.Message.getFieldWithDefault(msg, 20, ""),
    liveDistrict: jspb.Message.getFieldWithDefault(msg, 21, ""),
    liveAddress: jspb.Message.getFieldWithDefault(msg, 22, ""),
    snils: jspb.Message.getFieldWithDefault(msg, 23, 0),
    info: jspb.Message.getFieldWithDefault(msg, 24, ""),
    isBlocked: jspb.Message.getFieldWithDefault(msg, 25, 0),
    isTrusted: jspb.Message.getFieldWithDefault(msg, 26, 0),
    projectShortName: jspb.Message.getFieldWithDefault(msg, 27, ""),
    projectName: jspb.Message.getFieldWithDefault(msg, 28, ""),
    typeName: jspb.Message.getFieldWithDefault(msg, 29, ""),
    typeDescr: jspb.Message.getFieldWithDefault(msg, 30, ""),
    isDeleted: jspb.Message.getFieldWithDefault(msg, 31, 0),
    deletedDate: jspb.Message.getFieldWithDefault(msg, 32, ""),
    vkProfile: jspb.Message.getFieldWithDefault(msg, 33, ""),
    hobby: jspb.Message.getFieldWithDefault(msg, 34, ""),
    vkMessagesList: (f = jspb.Message.getRepeatedField(msg, 35)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AccountSrc}
 */
proto.proto.AccountSrc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AccountSrc;
  return proto.proto.AccountSrc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AccountSrc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AccountSrc}
 */
proto.proto.AccountSrc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSsoId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProjectId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceCode(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTypeId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBirthday(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSex(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setFamilyStatus(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDistrict(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumChield(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIsPublic(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkRegion(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkDistrict(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkAddress(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setSocial(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFill(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveRegion(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveDistrict(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveAddress(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSnils(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setInfo(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIsBlocked(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIsTrusted(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectShortName(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectName(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setTypeName(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setTypeDescr(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIsDeleted(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeletedDate(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setVkProfile(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setHobby(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.addVkMessages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AccountSrc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AccountSrc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AccountSrc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AccountSrc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSsoId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProjectId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getRegDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSourceCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTypeId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBirthday();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSex();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFamilyStatus();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDistrict();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getNumChield();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getIsPublic();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getWorkRegion();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getWorkDistrict();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getWorkAddress();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getSocial();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getFill();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = message.getLiveRegion();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getLiveDistrict();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getLiveAddress();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getSnils();
  if (f !== 0) {
    writer.writeInt64(
      23,
      f
    );
  }
  f = message.getInfo();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getIsBlocked();
  if (f !== 0) {
    writer.writeInt64(
      25,
      f
    );
  }
  f = message.getIsTrusted();
  if (f !== 0) {
    writer.writeInt64(
      26,
      f
    );
  }
  f = message.getProjectShortName();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getProjectName();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getTypeName();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getTypeDescr();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getIsDeleted();
  if (f !== 0) {
    writer.writeInt64(
      31,
      f
    );
  }
  f = message.getDeletedDate();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getVkProfile();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getHobby();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getVkMessagesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      35,
      f
    );
  }
};


/**
 * optional string sso_id = 1;
 * @return {string}
 */
proto.proto.AccountSrc.prototype.getSsoId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.setSsoId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 project_id = 2;
 * @return {number}
 */
proto.proto.AccountSrc.prototype.getProjectId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string reg_date = 3;
 * @return {string}
 */
proto.proto.AccountSrc.prototype.getRegDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.setRegDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string source_code = 4;
 * @return {string}
 */
proto.proto.AccountSrc.prototype.getSourceCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.setSourceCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 type_id = 5;
 * @return {number}
 */
proto.proto.AccountSrc.prototype.getTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.setTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string full_name = 6;
 * @return {string}
 */
proto.proto.AccountSrc.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.setFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string birthday = 7;
 * @return {string}
 */
proto.proto.AccountSrc.prototype.getBirthday = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.setBirthday = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string sex = 8;
 * @return {string}
 */
proto.proto.AccountSrc.prototype.getSex = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.setSex = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string family_status = 9;
 * @return {string}
 */
proto.proto.AccountSrc.prototype.getFamilyStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.setFamilyStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string region = 10;
 * @return {string}
 */
proto.proto.AccountSrc.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.setRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string district = 11;
 * @return {string}
 */
proto.proto.AccountSrc.prototype.getDistrict = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.setDistrict = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string address = 12;
 * @return {string}
 */
proto.proto.AccountSrc.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional int64 num_chield = 13;
 * @return {number}
 */
proto.proto.AccountSrc.prototype.getNumChield = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.setNumChield = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 is_public = 14;
 * @return {number}
 */
proto.proto.AccountSrc.prototype.getIsPublic = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.setIsPublic = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string work_region = 15;
 * @return {string}
 */
proto.proto.AccountSrc.prototype.getWorkRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.setWorkRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string work_district = 16;
 * @return {string}
 */
proto.proto.AccountSrc.prototype.getWorkDistrict = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.setWorkDistrict = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string work_address = 17;
 * @return {string}
 */
proto.proto.AccountSrc.prototype.getWorkAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.setWorkAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string social = 18;
 * @return {string}
 */
proto.proto.AccountSrc.prototype.getSocial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.setSocial = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional int64 fill = 19;
 * @return {number}
 */
proto.proto.AccountSrc.prototype.getFill = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.setFill = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional string live_region = 20;
 * @return {string}
 */
proto.proto.AccountSrc.prototype.getLiveRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.setLiveRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string live_district = 21;
 * @return {string}
 */
proto.proto.AccountSrc.prototype.getLiveDistrict = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.setLiveDistrict = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string live_address = 22;
 * @return {string}
 */
proto.proto.AccountSrc.prototype.getLiveAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.setLiveAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional int64 snils = 23;
 * @return {number}
 */
proto.proto.AccountSrc.prototype.getSnils = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.setSnils = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional string info = 24;
 * @return {string}
 */
proto.proto.AccountSrc.prototype.getInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.setInfo = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional int64 is_blocked = 25;
 * @return {number}
 */
proto.proto.AccountSrc.prototype.getIsBlocked = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.setIsBlocked = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional int64 is_trusted = 26;
 * @return {number}
 */
proto.proto.AccountSrc.prototype.getIsTrusted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.setIsTrusted = function(value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * optional string project_short_name = 27;
 * @return {string}
 */
proto.proto.AccountSrc.prototype.getProjectShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.setProjectShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string project_name = 28;
 * @return {string}
 */
proto.proto.AccountSrc.prototype.getProjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.setProjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string type_name = 29;
 * @return {string}
 */
proto.proto.AccountSrc.prototype.getTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.setTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string type_descr = 30;
 * @return {string}
 */
proto.proto.AccountSrc.prototype.getTypeDescr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.setTypeDescr = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional int64 is_deleted = 31;
 * @return {number}
 */
proto.proto.AccountSrc.prototype.getIsDeleted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 31, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.setIsDeleted = function(value) {
  return jspb.Message.setProto3IntField(this, 31, value);
};


/**
 * optional string deleted_date = 32;
 * @return {string}
 */
proto.proto.AccountSrc.prototype.getDeletedDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.setDeletedDate = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string vk_profile = 33;
 * @return {string}
 */
proto.proto.AccountSrc.prototype.getVkProfile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.setVkProfile = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional string hobby = 34;
 * @return {string}
 */
proto.proto.AccountSrc.prototype.getHobby = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.setHobby = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * repeated string vk_messages = 35;
 * @return {!Array<string>}
 */
proto.proto.AccountSrc.prototype.getVkMessagesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 35));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.setVkMessagesList = function(value) {
  return jspb.Message.setField(this, 35, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.addVkMessages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 35, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.AccountSrc} returns this
 */
proto.proto.AccountSrc.prototype.clearVkMessagesList = function() {
  return this.setVkMessagesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListAccountSrcRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListAccountSrcRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListAccountSrcRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAccountSrcRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ssoId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListAccountSrcRequest}
 */
proto.proto.ListAccountSrcRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListAccountSrcRequest;
  return proto.proto.ListAccountSrcRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListAccountSrcRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListAccountSrcRequest}
 */
proto.proto.ListAccountSrcRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSsoId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListAccountSrcRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListAccountSrcRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListAccountSrcRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAccountSrcRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSsoId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string sso_id = 1;
 * @return {string}
 */
proto.proto.ListAccountSrcRequest.prototype.getSsoId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListAccountSrcRequest} returns this
 */
proto.proto.ListAccountSrcRequest.prototype.setSsoId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListAccountSrcResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListAccountSrcResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListAccountSrcResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListAccountSrcResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAccountSrcResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.AccountSrc.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListAccountSrcResponse}
 */
proto.proto.ListAccountSrcResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListAccountSrcResponse;
  return proto.proto.ListAccountSrcResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListAccountSrcResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListAccountSrcResponse}
 */
proto.proto.ListAccountSrcResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AccountSrc;
      reader.readMessage(value,proto.proto.AccountSrc.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListAccountSrcResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListAccountSrcResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListAccountSrcResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAccountSrcResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.AccountSrc.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AccountSrc items = 1;
 * @return {!Array<!proto.proto.AccountSrc>}
 */
proto.proto.ListAccountSrcResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.AccountSrc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.AccountSrc, 1));
};


/**
 * @param {!Array<!proto.proto.AccountSrc>} value
 * @return {!proto.proto.ListAccountSrcResponse} returns this
*/
proto.proto.ListAccountSrcResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.AccountSrc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.AccountSrc}
 */
proto.proto.ListAccountSrcResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.AccountSrc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListAccountSrcResponse} returns this
 */
proto.proto.ListAccountSrcResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ItemAccountSrcRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ItemAccountSrcRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ItemAccountSrcRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ItemAccountSrcRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ssoId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    projectId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ItemAccountSrcRequest}
 */
proto.proto.ItemAccountSrcRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ItemAccountSrcRequest;
  return proto.proto.ItemAccountSrcRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ItemAccountSrcRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ItemAccountSrcRequest}
 */
proto.proto.ItemAccountSrcRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSsoId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ItemAccountSrcRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ItemAccountSrcRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ItemAccountSrcRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ItemAccountSrcRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSsoId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProjectId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string sso_id = 1;
 * @return {string}
 */
proto.proto.ItemAccountSrcRequest.prototype.getSsoId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ItemAccountSrcRequest} returns this
 */
proto.proto.ItemAccountSrcRequest.prototype.setSsoId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 project_id = 2;
 * @return {number}
 */
proto.proto.ItemAccountSrcRequest.prototype.getProjectId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ItemAccountSrcRequest} returns this
 */
proto.proto.ItemAccountSrcRequest.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ItemAccountSrcResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ItemAccountSrcResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ItemAccountSrcResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ItemAccountSrcResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    item: (f = msg.getItem()) && proto.proto.AccountSrc.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ItemAccountSrcResponse}
 */
proto.proto.ItemAccountSrcResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ItemAccountSrcResponse;
  return proto.proto.ItemAccountSrcResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ItemAccountSrcResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ItemAccountSrcResponse}
 */
proto.proto.ItemAccountSrcResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AccountSrc;
      reader.readMessage(value,proto.proto.AccountSrc.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ItemAccountSrcResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ItemAccountSrcResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ItemAccountSrcResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ItemAccountSrcResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.AccountSrc.serializeBinaryToWriter
    );
  }
};


/**
 * optional AccountSrc item = 1;
 * @return {?proto.proto.AccountSrc}
 */
proto.proto.ItemAccountSrcResponse.prototype.getItem = function() {
  return /** @type{?proto.proto.AccountSrc} */ (
    jspb.Message.getWrapperField(this, proto.proto.AccountSrc, 1));
};


/**
 * @param {?proto.proto.AccountSrc|undefined} value
 * @return {!proto.proto.ItemAccountSrcResponse} returns this
*/
proto.proto.ItemAccountSrcResponse.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ItemAccountSrcResponse} returns this
 */
proto.proto.ItemAccountSrcResponse.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ItemAccountSrcResponse.prototype.hasItem = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.proto);
module.exports = proto.proto;
