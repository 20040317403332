import { PointServiceClient } from "../../proto/pb/PointServiceClientPb";
import {
  CountPointRequest,
  ListPointEarnedRequest,
  ListPointSpentRequest,
  ListPointEarnedSheduleRequest,
  ListPointSpentSheduleRequest,
  ListPointBalanceSheduleRequest,
  CountPointSegmentRequest,
  CountPointEarnedByProjectRequest,
  ListPointTypesRequest
} from "../../proto/pb/point_pb";
import {
  Sort,
  Pagination
} from "../../proto/pb/filter_pb"
import { RefreshTokenInterceptor } from "@/components/modules/users/services/api/RefreshTokenInterceptor";

const defaultLimit: number = 1000;
export class PointsApi {
  client: PointServiceClient;
  metadata: any;

  constructor() {
    const host: string = (window as any).VUE_APP_API_URL ?? "";
    this.client = new PointServiceClient(host, null, {
      unaryInterceptors: [new RefreshTokenInterceptor()],
    });
    this.metadata = {
      Authorization: localStorage.getItem("access-key"),
    };
  }

  createSort(data: any) {
    const sort = new Sort();
    const name: string =
      data.name !== undefined ? data.name.toString() : "point_date";
    const exp: string = data.exp !== undefined ? data.exp.toString() : "desc";

    // Устанавливаем параметры сортировки
    sort.setName(name);
    sort.setExp(exp);

    return sort;
  }

  createPagination(data: any) {
    const pagination = new Pagination();
    const page: number = data.page !== undefined ? Number(data.page) : 1;
    const limit: number =
      data.limit !== undefined ? Number(data.limit) : defaultLimit;
    const pages: number = data.pages !== undefined ? Number(data.pages) : 0;
    const cnt: number = data.cnt !== undefined ? Number(data.cnt) : 0;

    // Устанавливаем пагинацию
    pagination.setPage(page);
    pagination.setLimit(limit);
    pagination.setPages(pages).setCnt(cnt);

    return pagination;
  }

  getEarnedPointsList(data: any, pagination: any, sort: any) {
    const request = new ListPointEarnedRequest();

    request
      .setSsoId(data.sso_id)
      .setProjectIdsList(data.project_ids)
      .setEventIdsList(data.event_ids || [])
      .setDateBegin(data.date_begin)
      .setDateEnd(data.date_end)
      .setPointTypeIdsList(data.point_type_ids || [])
      .setSort(this.createSort(sort))
      .setPagination(this.createPagination(pagination));

    console.log('%c[DEBUG]Get earned points list request', 'color:violet', request.toObject())

    return this.client.listPointEarned(request, {
      Authorization: localStorage.getItem("access-key"),
    } as any);
  }

  getSpentPointsList(data: any, pagination: any, sort: any) {
    const request = new ListPointSpentRequest();

    request
      .setSsoId(data.sso_id)
      .setProjectIdsList(data.project_ids || [])
      .setEventIdsList(data.event_ids || [])
      .setDateBegin(data.date_begin)
      .setDateEnd(data.date_end)
      .setPointTypeIdsList(data.point_type_ids || [])
      .setSort(this.createSort(sort))
      .setPagination(this.createPagination(pagination));
    
    return this.client.listPointSpent(request, {
      Authorization: localStorage.getItem("access-key"),
    } as any);
  }

  getEarnedChartList(data: ListPointEarnedSheduleRequest.AsObject) {
    const request = new ListPointEarnedSheduleRequest();

    request
      .setSsoId(data.ssoId)
      .setProjectIdsList(data.projectIdsList)
      .setActionNamesList(data.actionNamesList)
      .setDateBegin(data.dateBegin)
      .setDateEnd(data.dateEnd)
      .setMode(data.mode || "month");

    return this.client.listPointEarnedShedule(request, this.metadata);
  }

  getSpentChartList(data: ListPointSpentSheduleRequest.AsObject) {
    const request = new ListPointSpentSheduleRequest();

    request
      .setSsoId(data.ssoId)
      .setDateBegin(data.dateBegin)
      .setDateEnd(data.dateEnd)
      .setMode(data.mode || "month");

    return this.client.listPointSpentShedule(request, this.metadata);
  }

  getCountPoints(data: any) {
    const request = new CountPointRequest();

    request
      .setSsoId(data.sso_id)
      .setDateBegin(data.date_begin)
      .setDateEnd(data.date_end)
      .setProjectIdsList(data.project_ids || [])
      .setEventIdsList(data.event_ids || [])
      .setPointTypeIdsList(data.point_type_ids || [])
    
    return this.client.countPoint(request, {
      Authorization: localStorage.getItem("access-key"),
    } as any);
  }

  getListPointBalance(data: ListPointBalanceSheduleRequest.AsObject) {
    const request = new ListPointBalanceSheduleRequest();

    request
      .setSsoId(data.ssoId)
      .setDateBegin(data.dateBegin)
      .setDateEnd(data.dateEnd)
      .setMode(data.mode || "month");

    return this.client.listPointBalanceShedule(request, this.metadata);
  }

  getCountPointSegmentation(data: any) {
    const request = new CountPointSegmentRequest();

    request
      .setDateFrom(data.date_from)
      .setDateTo(data.date_to)
      .setPercent(data.percent)
      .setValue(data.value)
      .setProjectIdsList(data.project_ids || [])
      .setFromMetricAlias(data.from_name)
      .setToMetricAlias(data.to_name)
      .setIsAdditionalParams(data.add_param)
      .setFromMetricValueIdsList(data.from_ids)
      .setToMetricValueIdsList(data.to_ids);
    console.log(
      "🚀 ~ file: PointsApi.ts:216 ~ PointsApi ~ getCountPointSegmentation ~ request:",
      request
    );

    return this.client.countPointSegment(request, {
      Authorization: localStorage.getItem("access-key"),
    } as any);
  }

  getSumPointsEarned(data: CountPointEarnedByProjectRequest.AsObject) {
    const req = new CountPointEarnedByProjectRequest()

    req.setSsoId(data.ssoId)
      .setEventIdsList(data.eventIdsList || [])
      .setDateBegin(data.dateBegin)
      .setDateEnd(data.dateEnd)
      .setPointTypeIdsList(data.pointTypeIdsList || [])
      .setProjectIdsList(data.projectIdsList)
      .setSort(this.createSort(data.sort))

    console.log('%c[DEBUG]:Sum points earned request', 'color:violet', req.toObject())

    return this.client.countPointEarnedByProject(req, this.metadata)
      .then(res => {
        console.log('%c[API]:Sum points earned', 'color:lightblue', res.toObject())
        return res
      })
  }

  getPointTypes() {
    const req = new ListPointTypesRequest()
    
    return this.client.listPointTypes(req, this.metadata)
  }
}
