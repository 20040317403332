import { StyleValue } from "vue";
import { RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [
  {
    path: '/recommend-system/segmentation-settings',
    name: 'SegmentationSettings',
    component: () => import('../pages/Segmentation/SegmentationPage.vue'),
    meta: {
      breadCrumb: [
        { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
        { text: 'Рекомендательная система', to: { name: 'RecSystem' } },
        { text: 'Настройка сегментов' }
      ],
    },
  },
  {
    path: '/recommend-system/segmentation-settings/segmentation-action',
    name: 'SegmentationSettingsAction',
    component: () => import('../pages/Segmentation/SegmentationActionPage.vue'),
    meta: {
      breadCrumb: [
        { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
        { text: 'Рекомендательная система', to: { name: 'RecSystem' } },
        { text: 'Настройка сегментов', to: { name: 'SegmentationSettings' } },
        { text: 'Сегмент' }
      ],
      style: {
        backgroundColor: 'white',
        transition: 'all .3s ease'
      } satisfies StyleValue
    },
  },
]

export default routes
