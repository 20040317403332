// source: metric_account_value.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.proto.AccountActivityIntersectionMetricAccountValue', null, global);
goog.exportSymbol('proto.proto.AccountListIntersectionActivityMetricAccountValueRequest', null, global);
goog.exportSymbol('proto.proto.AccountListIntersectionActivityMetricAccountValueResponse', null, global);
goog.exportSymbol('proto.proto.ActivityIntersectionMetricAccountValue', null, global);
goog.exportSymbol('proto.proto.ActivityIntersectionMetricAccountValuePagination', null, global);
goog.exportSymbol('proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest', null, global);
goog.exportSymbol('proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueResponse', null, global);
goog.exportSymbol('proto.proto.DbscanActivityIntersectionMetricAccountValue', null, global);
goog.exportSymbol('proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest', null, global);
goog.exportSymbol('proto.proto.DbscanListIntersectionActivityMetricAccountValueResponse', null, global);
goog.exportSymbol('proto.proto.ListIntersectionActivityMetricAccountValueRequest', null, global);
goog.exportSymbol('proto.proto.ListIntersectionActivityMetricAccountValueResponse', null, global);
goog.exportSymbol('proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest', null, global);
goog.exportSymbol('proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ActivityIntersectionMetricAccountValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ActivityIntersectionMetricAccountValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ActivityIntersectionMetricAccountValue.displayName = 'proto.proto.ActivityIntersectionMetricAccountValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DbscanActivityIntersectionMetricAccountValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DbscanActivityIntersectionMetricAccountValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DbscanActivityIntersectionMetricAccountValue.displayName = 'proto.proto.DbscanActivityIntersectionMetricAccountValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AccountActivityIntersectionMetricAccountValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AccountActivityIntersectionMetricAccountValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AccountActivityIntersectionMetricAccountValue.displayName = 'proto.proto.AccountActivityIntersectionMetricAccountValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ActivityIntersectionMetricAccountValuePagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ActivityIntersectionMetricAccountValuePagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ActivityIntersectionMetricAccountValuePagination.displayName = 'proto.proto.ActivityIntersectionMetricAccountValuePagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListIntersectionActivityMetricAccountValueRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListIntersectionActivityMetricAccountValueRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.ListIntersectionActivityMetricAccountValueRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListIntersectionActivityMetricAccountValueRequest.displayName = 'proto.proto.ListIntersectionActivityMetricAccountValueRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest.displayName = 'proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.AccountListIntersectionActivityMetricAccountValueRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.displayName = 'proto.proto.AccountListIntersectionActivityMetricAccountValueRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListIntersectionActivityMetricAccountValueResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListIntersectionActivityMetricAccountValueResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListIntersectionActivityMetricAccountValueResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListIntersectionActivityMetricAccountValueResponse.displayName = 'proto.proto.ListIntersectionActivityMetricAccountValueResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.DbscanListIntersectionActivityMetricAccountValueResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.DbscanListIntersectionActivityMetricAccountValueResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DbscanListIntersectionActivityMetricAccountValueResponse.displayName = 'proto.proto.DbscanListIntersectionActivityMetricAccountValueResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.AccountListIntersectionActivityMetricAccountValueResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.AccountListIntersectionActivityMetricAccountValueResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AccountListIntersectionActivityMetricAccountValueResponse.displayName = 'proto.proto.AccountListIntersectionActivityMetricAccountValueResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.displayName = 'proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueResponse.displayName = 'proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest.displayName = 'proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueResponse.displayName = 'proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ActivityIntersectionMetricAccountValue.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ActivityIntersectionMetricAccountValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ActivityIntersectionMetricAccountValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ActivityIntersectionMetricAccountValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    fromMetricValueId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    toMetricValueId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cnt: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ActivityIntersectionMetricAccountValue}
 */
proto.proto.ActivityIntersectionMetricAccountValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ActivityIntersectionMetricAccountValue;
  return proto.proto.ActivityIntersectionMetricAccountValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ActivityIntersectionMetricAccountValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ActivityIntersectionMetricAccountValue}
 */
proto.proto.ActivityIntersectionMetricAccountValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFromMetricValueId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setToMetricValueId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCnt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ActivityIntersectionMetricAccountValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ActivityIntersectionMetricAccountValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ActivityIntersectionMetricAccountValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ActivityIntersectionMetricAccountValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFromMetricValueId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getToMetricValueId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getCnt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional uint64 from_metric_value_id = 1;
 * @return {number}
 */
proto.proto.ActivityIntersectionMetricAccountValue.prototype.getFromMetricValueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ActivityIntersectionMetricAccountValue} returns this
 */
proto.proto.ActivityIntersectionMetricAccountValue.prototype.setFromMetricValueId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 to_metric_value_id = 2;
 * @return {number}
 */
proto.proto.ActivityIntersectionMetricAccountValue.prototype.getToMetricValueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ActivityIntersectionMetricAccountValue} returns this
 */
proto.proto.ActivityIntersectionMetricAccountValue.prototype.setToMetricValueId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 cnt = 3;
 * @return {number}
 */
proto.proto.ActivityIntersectionMetricAccountValue.prototype.getCnt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ActivityIntersectionMetricAccountValue} returns this
 */
proto.proto.ActivityIntersectionMetricAccountValue.prototype.setCnt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DbscanActivityIntersectionMetricAccountValue.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DbscanActivityIntersectionMetricAccountValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DbscanActivityIntersectionMetricAccountValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DbscanActivityIntersectionMetricAccountValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    fromMetricPercent: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fromMetricOrder: jspb.Message.getFieldWithDefault(msg, 2, 0),
    toMetricPercent: jspb.Message.getFieldWithDefault(msg, 3, ""),
    toMetricOrder: jspb.Message.getFieldWithDefault(msg, 4, 0),
    cnt: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DbscanActivityIntersectionMetricAccountValue}
 */
proto.proto.DbscanActivityIntersectionMetricAccountValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DbscanActivityIntersectionMetricAccountValue;
  return proto.proto.DbscanActivityIntersectionMetricAccountValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DbscanActivityIntersectionMetricAccountValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DbscanActivityIntersectionMetricAccountValue}
 */
proto.proto.DbscanActivityIntersectionMetricAccountValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromMetricPercent(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFromMetricOrder(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setToMetricPercent(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setToMetricOrder(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCnt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DbscanActivityIntersectionMetricAccountValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DbscanActivityIntersectionMetricAccountValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DbscanActivityIntersectionMetricAccountValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DbscanActivityIntersectionMetricAccountValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFromMetricPercent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFromMetricOrder();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getToMetricPercent();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getToMetricOrder();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getCnt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional string from_metric_percent = 1;
 * @return {string}
 */
proto.proto.DbscanActivityIntersectionMetricAccountValue.prototype.getFromMetricPercent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DbscanActivityIntersectionMetricAccountValue} returns this
 */
proto.proto.DbscanActivityIntersectionMetricAccountValue.prototype.setFromMetricPercent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 from_metric_order = 2;
 * @return {number}
 */
proto.proto.DbscanActivityIntersectionMetricAccountValue.prototype.getFromMetricOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DbscanActivityIntersectionMetricAccountValue} returns this
 */
proto.proto.DbscanActivityIntersectionMetricAccountValue.prototype.setFromMetricOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string to_metric_percent = 3;
 * @return {string}
 */
proto.proto.DbscanActivityIntersectionMetricAccountValue.prototype.getToMetricPercent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DbscanActivityIntersectionMetricAccountValue} returns this
 */
proto.proto.DbscanActivityIntersectionMetricAccountValue.prototype.setToMetricPercent = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint64 to_metric_order = 4;
 * @return {number}
 */
proto.proto.DbscanActivityIntersectionMetricAccountValue.prototype.getToMetricOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DbscanActivityIntersectionMetricAccountValue} returns this
 */
proto.proto.DbscanActivityIntersectionMetricAccountValue.prototype.setToMetricOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 cnt = 5;
 * @return {number}
 */
proto.proto.DbscanActivityIntersectionMetricAccountValue.prototype.getCnt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DbscanActivityIntersectionMetricAccountValue} returns this
 */
proto.proto.DbscanActivityIntersectionMetricAccountValue.prototype.setCnt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AccountActivityIntersectionMetricAccountValue.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AccountActivityIntersectionMetricAccountValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AccountActivityIntersectionMetricAccountValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AccountActivityIntersectionMetricAccountValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountGuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ssoId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AccountActivityIntersectionMetricAccountValue}
 */
proto.proto.AccountActivityIntersectionMetricAccountValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AccountActivityIntersectionMetricAccountValue;
  return proto.proto.AccountActivityIntersectionMetricAccountValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AccountActivityIntersectionMetricAccountValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AccountActivityIntersectionMetricAccountValue}
 */
proto.proto.AccountActivityIntersectionMetricAccountValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountGuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSsoId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AccountActivityIntersectionMetricAccountValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AccountActivityIntersectionMetricAccountValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AccountActivityIntersectionMetricAccountValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AccountActivityIntersectionMetricAccountValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountGuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSsoId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string account_guid = 1;
 * @return {string}
 */
proto.proto.AccountActivityIntersectionMetricAccountValue.prototype.getAccountGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountActivityIntersectionMetricAccountValue} returns this
 */
proto.proto.AccountActivityIntersectionMetricAccountValue.prototype.setAccountGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sso_id = 2;
 * @return {string}
 */
proto.proto.AccountActivityIntersectionMetricAccountValue.prototype.getSsoId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountActivityIntersectionMetricAccountValue} returns this
 */
proto.proto.AccountActivityIntersectionMetricAccountValue.prototype.setSsoId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ActivityIntersectionMetricAccountValuePagination.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ActivityIntersectionMetricAccountValuePagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ActivityIntersectionMetricAccountValuePagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ActivityIntersectionMetricAccountValuePagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pages: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cnt: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ActivityIntersectionMetricAccountValuePagination}
 */
proto.proto.ActivityIntersectionMetricAccountValuePagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ActivityIntersectionMetricAccountValuePagination;
  return proto.proto.ActivityIntersectionMetricAccountValuePagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ActivityIntersectionMetricAccountValuePagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ActivityIntersectionMetricAccountValuePagination}
 */
proto.proto.ActivityIntersectionMetricAccountValuePagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPages(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCnt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ActivityIntersectionMetricAccountValuePagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ActivityIntersectionMetricAccountValuePagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ActivityIntersectionMetricAccountValuePagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ActivityIntersectionMetricAccountValuePagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPages();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCnt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 page = 1;
 * @return {number}
 */
proto.proto.ActivityIntersectionMetricAccountValuePagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ActivityIntersectionMetricAccountValuePagination} returns this
 */
proto.proto.ActivityIntersectionMetricAccountValuePagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 limit = 2;
 * @return {number}
 */
proto.proto.ActivityIntersectionMetricAccountValuePagination.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ActivityIntersectionMetricAccountValuePagination} returns this
 */
proto.proto.ActivityIntersectionMetricAccountValuePagination.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 pages = 3;
 * @return {number}
 */
proto.proto.ActivityIntersectionMetricAccountValuePagination.prototype.getPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ActivityIntersectionMetricAccountValuePagination} returns this
 */
proto.proto.ActivityIntersectionMetricAccountValuePagination.prototype.setPages = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 cnt = 4;
 * @return {number}
 */
proto.proto.ActivityIntersectionMetricAccountValuePagination.prototype.getCnt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ActivityIntersectionMetricAccountValuePagination} returns this
 */
proto.proto.ActivityIntersectionMetricAccountValuePagination.prototype.setCnt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListIntersectionActivityMetricAccountValueRequest.repeatedFields_ = [1,3,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListIntersectionActivityMetricAccountValueRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListIntersectionActivityMetricAccountValueRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListIntersectionActivityMetricAccountValueRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListIntersectionActivityMetricAccountValueRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    fromMetricAlias: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fromMetricValueIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    toMetricAlias: jspb.Message.getFieldWithDefault(msg, 4, ""),
    toMetricValueIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    dateFrom: jspb.Message.getFieldWithDefault(msg, 6, ""),
    dateTo: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isAdditionalParams: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListIntersectionActivityMetricAccountValueRequest}
 */
proto.proto.ListIntersectionActivityMetricAccountValueRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListIntersectionActivityMetricAccountValueRequest;
  return proto.proto.ListIntersectionActivityMetricAccountValueRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListIntersectionActivityMetricAccountValueRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListIntersectionActivityMetricAccountValueRequest}
 */
proto.proto.ListIntersectionActivityMetricAccountValueRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromMetricAlias(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFromMetricValueIds(values[i]);
      }
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setToMetricAlias(value);
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addToMetricValueIds(values[i]);
      }
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateFrom(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateTo(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAdditionalParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListIntersectionActivityMetricAccountValueRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListIntersectionActivityMetricAccountValueRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListIntersectionActivityMetricAccountValueRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListIntersectionActivityMetricAccountValueRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      1,
      f
    );
  }
  f = message.getFromMetricAlias();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFromMetricValueIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      3,
      f
    );
  }
  f = message.getToMetricAlias();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getToMetricValueIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      5,
      f
    );
  }
  f = message.getDateFrom();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDateTo();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsAdditionalParams();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * repeated uint64 project_ids = 1;
 * @return {!Array<number>}
 */
proto.proto.ListIntersectionActivityMetricAccountValueRequest.prototype.getProjectIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.ListIntersectionActivityMetricAccountValueRequest.prototype.setProjectIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.ListIntersectionActivityMetricAccountValueRequest.prototype.addProjectIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.ListIntersectionActivityMetricAccountValueRequest.prototype.clearProjectIdsList = function() {
  return this.setProjectIdsList([]);
};


/**
 * optional string from_metric_alias = 2;
 * @return {string}
 */
proto.proto.ListIntersectionActivityMetricAccountValueRequest.prototype.getFromMetricAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.ListIntersectionActivityMetricAccountValueRequest.prototype.setFromMetricAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated uint64 from_metric_value_ids = 3;
 * @return {!Array<number>}
 */
proto.proto.ListIntersectionActivityMetricAccountValueRequest.prototype.getFromMetricValueIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.ListIntersectionActivityMetricAccountValueRequest.prototype.setFromMetricValueIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.ListIntersectionActivityMetricAccountValueRequest.prototype.addFromMetricValueIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.ListIntersectionActivityMetricAccountValueRequest.prototype.clearFromMetricValueIdsList = function() {
  return this.setFromMetricValueIdsList([]);
};


/**
 * optional string to_metric_alias = 4;
 * @return {string}
 */
proto.proto.ListIntersectionActivityMetricAccountValueRequest.prototype.getToMetricAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.ListIntersectionActivityMetricAccountValueRequest.prototype.setToMetricAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated uint64 to_metric_value_ids = 5;
 * @return {!Array<number>}
 */
proto.proto.ListIntersectionActivityMetricAccountValueRequest.prototype.getToMetricValueIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.ListIntersectionActivityMetricAccountValueRequest.prototype.setToMetricValueIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.ListIntersectionActivityMetricAccountValueRequest.prototype.addToMetricValueIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.ListIntersectionActivityMetricAccountValueRequest.prototype.clearToMetricValueIdsList = function() {
  return this.setToMetricValueIdsList([]);
};


/**
 * optional string date_from = 6;
 * @return {string}
 */
proto.proto.ListIntersectionActivityMetricAccountValueRequest.prototype.getDateFrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.ListIntersectionActivityMetricAccountValueRequest.prototype.setDateFrom = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string date_to = 7;
 * @return {string}
 */
proto.proto.ListIntersectionActivityMetricAccountValueRequest.prototype.getDateTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.ListIntersectionActivityMetricAccountValueRequest.prototype.setDateTo = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool is_additional_params = 8;
 * @return {boolean}
 */
proto.proto.ListIntersectionActivityMetricAccountValueRequest.prototype.getIsAdditionalParams = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.ListIntersectionActivityMetricAccountValueRequest.prototype.setIsAdditionalParams = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest.repeatedFields_ = [1,3,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    fromMetricAlias: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fromMetricValueIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    toMetricAlias: jspb.Message.getFieldWithDefault(msg, 4, ""),
    toMetricValueIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    dateFrom: jspb.Message.getFieldWithDefault(msg, 6, ""),
    dateTo: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isAdditionalParams: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest}
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest;
  return proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest}
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromMetricAlias(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFromMetricValueIds(values[i]);
      }
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setToMetricAlias(value);
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addToMetricValueIds(values[i]);
      }
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateFrom(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateTo(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAdditionalParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      1,
      f
    );
  }
  f = message.getFromMetricAlias();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFromMetricValueIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      3,
      f
    );
  }
  f = message.getToMetricAlias();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getToMetricValueIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      5,
      f
    );
  }
  f = message.getDateFrom();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDateTo();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsAdditionalParams();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * repeated uint64 project_ids = 1;
 * @return {!Array<number>}
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest.prototype.getProjectIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest.prototype.setProjectIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest.prototype.addProjectIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest.prototype.clearProjectIdsList = function() {
  return this.setProjectIdsList([]);
};


/**
 * optional string from_metric_alias = 2;
 * @return {string}
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest.prototype.getFromMetricAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest.prototype.setFromMetricAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated uint64 from_metric_value_ids = 3;
 * @return {!Array<number>}
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest.prototype.getFromMetricValueIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest.prototype.setFromMetricValueIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest.prototype.addFromMetricValueIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest.prototype.clearFromMetricValueIdsList = function() {
  return this.setFromMetricValueIdsList([]);
};


/**
 * optional string to_metric_alias = 4;
 * @return {string}
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest.prototype.getToMetricAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest.prototype.setToMetricAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated uint64 to_metric_value_ids = 5;
 * @return {!Array<number>}
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest.prototype.getToMetricValueIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest.prototype.setToMetricValueIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest.prototype.addToMetricValueIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest.prototype.clearToMetricValueIdsList = function() {
  return this.setToMetricValueIdsList([]);
};


/**
 * optional string date_from = 6;
 * @return {string}
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest.prototype.getDateFrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest.prototype.setDateFrom = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string date_to = 7;
 * @return {string}
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest.prototype.getDateTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest.prototype.setDateTo = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool is_additional_params = 8;
 * @return {boolean}
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest.prototype.getIsAdditionalParams = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueRequest.prototype.setIsAdditionalParams = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AccountListIntersectionActivityMetricAccountValueRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    fromMetricAlias: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fromMetricValueId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    toMetricAlias: jspb.Message.getFieldWithDefault(msg, 4, ""),
    toMetricValueId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    dateFrom: jspb.Message.getFieldWithDefault(msg, 6, ""),
    dateTo: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isAdditionalParams: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    pagination: (f = msg.getPagination()) && proto.proto.ActivityIntersectionMetricAccountValuePagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AccountListIntersectionActivityMetricAccountValueRequest}
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AccountListIntersectionActivityMetricAccountValueRequest;
  return proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AccountListIntersectionActivityMetricAccountValueRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AccountListIntersectionActivityMetricAccountValueRequest}
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromMetricAlias(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFromMetricValueId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setToMetricAlias(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setToMetricValueId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateFrom(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateTo(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAdditionalParams(value);
      break;
    case 9:
      var value = new proto.proto.ActivityIntersectionMetricAccountValuePagination;
      reader.readMessage(value,proto.proto.ActivityIntersectionMetricAccountValuePagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AccountListIntersectionActivityMetricAccountValueRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      1,
      f
    );
  }
  f = message.getFromMetricAlias();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFromMetricValueId();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getToMetricAlias();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getToMetricValueId();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getDateFrom();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDateTo();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsAdditionalParams();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.proto.ActivityIntersectionMetricAccountValuePagination.serializeBinaryToWriter
    );
  }
};


/**
 * repeated uint64 project_ids = 1;
 * @return {!Array<number>}
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.prototype.getProjectIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.AccountListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.prototype.setProjectIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.AccountListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.prototype.addProjectIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.AccountListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.prototype.clearProjectIdsList = function() {
  return this.setProjectIdsList([]);
};


/**
 * optional string from_metric_alias = 2;
 * @return {string}
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.prototype.getFromMetricAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.prototype.setFromMetricAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 from_metric_value_id = 3;
 * @return {number}
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.prototype.getFromMetricValueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AccountListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.prototype.setFromMetricValueId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string to_metric_alias = 4;
 * @return {string}
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.prototype.getToMetricAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.prototype.setToMetricAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint64 to_metric_value_id = 5;
 * @return {number}
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.prototype.getToMetricValueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AccountListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.prototype.setToMetricValueId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string date_from = 6;
 * @return {string}
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.prototype.getDateFrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.prototype.setDateFrom = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string date_to = 7;
 * @return {string}
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.prototype.getDateTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.prototype.setDateTo = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool is_additional_params = 8;
 * @return {boolean}
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.prototype.getIsAdditionalParams = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.AccountListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.prototype.setIsAdditionalParams = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional ActivityIntersectionMetricAccountValuePagination pagination = 9;
 * @return {?proto.proto.ActivityIntersectionMetricAccountValuePagination}
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.ActivityIntersectionMetricAccountValuePagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.ActivityIntersectionMetricAccountValuePagination, 9));
};


/**
 * @param {?proto.proto.ActivityIntersectionMetricAccountValuePagination|undefined} value
 * @return {!proto.proto.AccountListIntersectionActivityMetricAccountValueRequest} returns this
*/
proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AccountListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListIntersectionActivityMetricAccountValueResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListIntersectionActivityMetricAccountValueResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListIntersectionActivityMetricAccountValueResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListIntersectionActivityMetricAccountValueResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListIntersectionActivityMetricAccountValueResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.ActivityIntersectionMetricAccountValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListIntersectionActivityMetricAccountValueResponse}
 */
proto.proto.ListIntersectionActivityMetricAccountValueResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListIntersectionActivityMetricAccountValueResponse;
  return proto.proto.ListIntersectionActivityMetricAccountValueResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListIntersectionActivityMetricAccountValueResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListIntersectionActivityMetricAccountValueResponse}
 */
proto.proto.ListIntersectionActivityMetricAccountValueResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.ActivityIntersectionMetricAccountValue;
      reader.readMessage(value,proto.proto.ActivityIntersectionMetricAccountValue.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListIntersectionActivityMetricAccountValueResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListIntersectionActivityMetricAccountValueResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListIntersectionActivityMetricAccountValueResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListIntersectionActivityMetricAccountValueResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.ActivityIntersectionMetricAccountValue.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ActivityIntersectionMetricAccountValue items = 1;
 * @return {!Array<!proto.proto.ActivityIntersectionMetricAccountValue>}
 */
proto.proto.ListIntersectionActivityMetricAccountValueResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.ActivityIntersectionMetricAccountValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.ActivityIntersectionMetricAccountValue, 1));
};


/**
 * @param {!Array<!proto.proto.ActivityIntersectionMetricAccountValue>} value
 * @return {!proto.proto.ListIntersectionActivityMetricAccountValueResponse} returns this
*/
proto.proto.ListIntersectionActivityMetricAccountValueResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.ActivityIntersectionMetricAccountValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.ActivityIntersectionMetricAccountValue}
 */
proto.proto.ListIntersectionActivityMetricAccountValueResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.ActivityIntersectionMetricAccountValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListIntersectionActivityMetricAccountValueResponse} returns this
 */
proto.proto.ListIntersectionActivityMetricAccountValueResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DbscanListIntersectionActivityMetricAccountValueResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DbscanListIntersectionActivityMetricAccountValueResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DbscanActivityIntersectionMetricAccountValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DbscanListIntersectionActivityMetricAccountValueResponse}
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DbscanListIntersectionActivityMetricAccountValueResponse;
  return proto.proto.DbscanListIntersectionActivityMetricAccountValueResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DbscanListIntersectionActivityMetricAccountValueResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DbscanListIntersectionActivityMetricAccountValueResponse}
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DbscanActivityIntersectionMetricAccountValue;
      reader.readMessage(value,proto.proto.DbscanActivityIntersectionMetricAccountValue.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DbscanListIntersectionActivityMetricAccountValueResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DbscanListIntersectionActivityMetricAccountValueResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DbscanActivityIntersectionMetricAccountValue.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DbscanActivityIntersectionMetricAccountValue items = 1;
 * @return {!Array<!proto.proto.DbscanActivityIntersectionMetricAccountValue>}
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DbscanActivityIntersectionMetricAccountValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DbscanActivityIntersectionMetricAccountValue, 1));
};


/**
 * @param {!Array<!proto.proto.DbscanActivityIntersectionMetricAccountValue>} value
 * @return {!proto.proto.DbscanListIntersectionActivityMetricAccountValueResponse} returns this
*/
proto.proto.DbscanListIntersectionActivityMetricAccountValueResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DbscanActivityIntersectionMetricAccountValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DbscanActivityIntersectionMetricAccountValue}
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DbscanActivityIntersectionMetricAccountValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.DbscanListIntersectionActivityMetricAccountValueResponse} returns this
 */
proto.proto.DbscanListIntersectionActivityMetricAccountValueResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AccountListIntersectionActivityMetricAccountValueResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AccountListIntersectionActivityMetricAccountValueResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.AccountActivityIntersectionMetricAccountValue.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AccountListIntersectionActivityMetricAccountValueResponse}
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AccountListIntersectionActivityMetricAccountValueResponse;
  return proto.proto.AccountListIntersectionActivityMetricAccountValueResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AccountListIntersectionActivityMetricAccountValueResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AccountListIntersectionActivityMetricAccountValueResponse}
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AccountActivityIntersectionMetricAccountValue;
      reader.readMessage(value,proto.proto.AccountActivityIntersectionMetricAccountValue.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.AccountListIntersectionActivityMetricAccountValueRequest;
      reader.readMessage(value,proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AccountListIntersectionActivityMetricAccountValueResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AccountListIntersectionActivityMetricAccountValueResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.AccountActivityIntersectionMetricAccountValue.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.AccountListIntersectionActivityMetricAccountValueRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AccountActivityIntersectionMetricAccountValue items = 1;
 * @return {!Array<!proto.proto.AccountActivityIntersectionMetricAccountValue>}
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.AccountActivityIntersectionMetricAccountValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.AccountActivityIntersectionMetricAccountValue, 1));
};


/**
 * @param {!Array<!proto.proto.AccountActivityIntersectionMetricAccountValue>} value
 * @return {!proto.proto.AccountListIntersectionActivityMetricAccountValueResponse} returns this
*/
proto.proto.AccountListIntersectionActivityMetricAccountValueResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.AccountActivityIntersectionMetricAccountValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.AccountActivityIntersectionMetricAccountValue}
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.AccountActivityIntersectionMetricAccountValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.AccountListIntersectionActivityMetricAccountValueResponse} returns this
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional AccountListIntersectionActivityMetricAccountValueRequest params = 2;
 * @return {?proto.proto.AccountListIntersectionActivityMetricAccountValueRequest}
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.AccountListIntersectionActivityMetricAccountValueRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.AccountListIntersectionActivityMetricAccountValueRequest, 2));
};


/**
 * @param {?proto.proto.AccountListIntersectionActivityMetricAccountValueRequest|undefined} value
 * @return {!proto.proto.AccountListIntersectionActivityMetricAccountValueResponse} returns this
*/
proto.proto.AccountListIntersectionActivityMetricAccountValueResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AccountListIntersectionActivityMetricAccountValueResponse} returns this
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AccountListIntersectionActivityMetricAccountValueResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    fromMetricAlias: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fromMetricOrder: jspb.Message.getFieldWithDefault(msg, 3, 0),
    toMetricAlias: jspb.Message.getFieldWithDefault(msg, 4, ""),
    toMetricOrder: jspb.Message.getFieldWithDefault(msg, 5, 0),
    dateFrom: jspb.Message.getFieldWithDefault(msg, 6, ""),
    dateTo: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isAdditionalParams: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    pagination: (f = msg.getPagination()) && proto.proto.ActivityIntersectionMetricAccountValuePagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest}
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest;
  return proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest}
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromMetricAlias(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFromMetricOrder(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setToMetricAlias(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setToMetricOrder(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateFrom(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateTo(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAdditionalParams(value);
      break;
    case 9:
      var value = new proto.proto.ActivityIntersectionMetricAccountValuePagination;
      reader.readMessage(value,proto.proto.ActivityIntersectionMetricAccountValuePagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      1,
      f
    );
  }
  f = message.getFromMetricAlias();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFromMetricOrder();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getToMetricAlias();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getToMetricOrder();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getDateFrom();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDateTo();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsAdditionalParams();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.proto.ActivityIntersectionMetricAccountValuePagination.serializeBinaryToWriter
    );
  }
};


/**
 * repeated uint64 project_ids = 1;
 * @return {!Array<number>}
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.prototype.getProjectIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.prototype.setProjectIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.prototype.addProjectIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.prototype.clearProjectIdsList = function() {
  return this.setProjectIdsList([]);
};


/**
 * optional string from_metric_alias = 2;
 * @return {string}
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.prototype.getFromMetricAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.prototype.setFromMetricAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 from_metric_order = 3;
 * @return {number}
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.prototype.getFromMetricOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.prototype.setFromMetricOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string to_metric_alias = 4;
 * @return {string}
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.prototype.getToMetricAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.prototype.setToMetricAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint64 to_metric_order = 5;
 * @return {number}
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.prototype.getToMetricOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.prototype.setToMetricOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string date_from = 6;
 * @return {string}
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.prototype.getDateFrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.prototype.setDateFrom = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string date_to = 7;
 * @return {string}
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.prototype.getDateTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.prototype.setDateTo = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool is_additional_params = 8;
 * @return {boolean}
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.prototype.getIsAdditionalParams = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.prototype.setIsAdditionalParams = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional ActivityIntersectionMetricAccountValuePagination pagination = 9;
 * @return {?proto.proto.ActivityIntersectionMetricAccountValuePagination}
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.ActivityIntersectionMetricAccountValuePagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.ActivityIntersectionMetricAccountValuePagination, 9));
};


/**
 * @param {?proto.proto.ActivityIntersectionMetricAccountValuePagination|undefined} value
 * @return {!proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest} returns this
*/
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.AccountActivityIntersectionMetricAccountValue.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueResponse}
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueResponse;
  return proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueResponse}
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AccountActivityIntersectionMetricAccountValue;
      reader.readMessage(value,proto.proto.AccountActivityIntersectionMetricAccountValue.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest;
      reader.readMessage(value,proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.AccountActivityIntersectionMetricAccountValue.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AccountActivityIntersectionMetricAccountValue items = 1;
 * @return {!Array<!proto.proto.AccountActivityIntersectionMetricAccountValue>}
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.AccountActivityIntersectionMetricAccountValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.AccountActivityIntersectionMetricAccountValue, 1));
};


/**
 * @param {!Array<!proto.proto.AccountActivityIntersectionMetricAccountValue>} value
 * @return {!proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueResponse} returns this
*/
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.AccountActivityIntersectionMetricAccountValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.AccountActivityIntersectionMetricAccountValue}
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.AccountActivityIntersectionMetricAccountValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueResponse} returns this
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional DbscanAccountListIntersectionActivityMetricAccountValueRequest params = 2;
 * @return {?proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest}
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest, 2));
};


/**
 * @param {?proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueRequest|undefined} value
 * @return {!proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueResponse} returns this
*/
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueResponse} returns this
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.DbscanAccountListIntersectionActivityMetricAccountValueResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    fromMetricAlias: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fromMetricValueId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    toMetricAlias: jspb.Message.getFieldWithDefault(msg, 4, ""),
    toMetricValueId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    dateFrom: jspb.Message.getFieldWithDefault(msg, 6, ""),
    dateTo: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isAdditionalParams: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest}
 */
proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest;
  return proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest}
 */
proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromMetricAlias(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFromMetricValueId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setToMetricAlias(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setToMetricValueId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateFrom(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateTo(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAdditionalParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      1,
      f
    );
  }
  f = message.getFromMetricAlias();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFromMetricValueId();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getToMetricAlias();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getToMetricValueId();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getDateFrom();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDateTo();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsAdditionalParams();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * repeated uint64 project_ids = 1;
 * @return {!Array<number>}
 */
proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest.prototype.getProjectIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest.prototype.setProjectIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest.prototype.addProjectIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest.prototype.clearProjectIdsList = function() {
  return this.setProjectIdsList([]);
};


/**
 * optional string from_metric_alias = 2;
 * @return {string}
 */
proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest.prototype.getFromMetricAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest.prototype.setFromMetricAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 from_metric_value_id = 3;
 * @return {number}
 */
proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest.prototype.getFromMetricValueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest.prototype.setFromMetricValueId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string to_metric_alias = 4;
 * @return {string}
 */
proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest.prototype.getToMetricAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest.prototype.setToMetricAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint64 to_metric_value_id = 5;
 * @return {number}
 */
proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest.prototype.getToMetricValueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest.prototype.setToMetricValueId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string date_from = 6;
 * @return {string}
 */
proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest.prototype.getDateFrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest.prototype.setDateFrom = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string date_to = 7;
 * @return {string}
 */
proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest.prototype.getDateTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest.prototype.setDateTo = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool is_additional_params = 8;
 * @return {boolean}
 */
proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest.prototype.getIsAdditionalParams = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest} returns this
 */
proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueRequest.prototype.setIsAdditionalParams = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueResponse}
 */
proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueResponse;
  return proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueResponse}
 */
proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueResponse} returns this
 */
proto.proto.PersonalAccountListIntersectionActivityMetricAccountValueResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


goog.object.extend(exports, proto.proto);
module.exports = proto.proto;
