import script from "./IntTableProjects.vue?vue&type=script&setup=true&lang=ts"
export * from "./IntTableProjects.vue?vue&type=script&setup=true&lang=ts"

import "./IntTableProjects.vue?vue&type=style&index=0&id=3b8b6538&lang=scss"

const __exports__ = script;

export default __exports__
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTree from 'quasar/src/components/tree/QTree.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QIcon,QTree});
